/**
    * Featured Content
    * -----------------------------------------------------------------------------
    * This is a composite component that is used to display featured content.
    *
    * The composite contains
    * - a title
    * - a description
    * - two buttons
    *    - one button can be an optional 'partner' button
    * - an image
    * @package Henry
*/

.content,
.featured_content {
  @include generate_color_schemes($createBorders: false);
}

.partner_link > span.partner_logo {
  margin-right: 1rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

.featured_content_splide {
  position: relative;
  
  &.use-carousel {

    .featured_content_item {
      .featured_content {

        @include media-breakpoint-up(md) {
          padding-bottom: 10rem;
        }
      }
    }

    .video-section-block {
      .video-section-video {
        height: 100%;
        margin-bottom: 7rem;
      }
    }
  }

  .splide__slide {
    > div {
      height: 100%;
      > article {
        height: 100%;
      }
    }
    .full_width_hero {
      height: 100%;
      .wrapper {
        height: 70rem;
        @include media-breakpoint-up(lg) {
          height: 64rem;
        }
        
        video,
        .content-wrapper {
          height: 100%;
        }
      }
    }

    .video-section-block {
      margin: unset;
      height: 100%;
      .video-section {
        height: 100%;
        .video-wrapper {
          height: 100%;
        }
      }
    }
  }

  .featured_content_pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: 0 auto;
    max-width: 11rem;
    width: 100%;
    border-radius: 2.5rem;
    background-color: $medium_gray;
    position: absolute;
    bottom: 2.2rem;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-wrap: nowrap;
    li {
      display: flex;
      place-content: center;
    }
  }

  .featured_content_page {
    border: none;
    background-color: $medium_gray;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &:before {
      content: "";
      display: block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: $white;
    }
    &.is-active {
      &:before {
        background-color: $medium_blue;
      }
    }
  }
}

.featured_content_item {
  height: 100%;
  > div {
    height: 100%;
  }
  > video {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      border-radius: 2rem;
      z-index: 1;
    }
  }

  .eyebrows {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 2.5rem;

    .category-tag {
        padding: 0.5rem 1rem;

        &.font_white:hover {
            color: $shadow_gray;
        }
    }
  }

  .has_bg_image {
    &.overlay {
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 48, 108, 0.7);
        z-index: 1;
      }
    }
  }

  &.mobile-variant {
    display: flex;
    flex-direction: column;

    .mobile-variant-content {
      display: none;
      @include media-breakpoint-down(md) {
        display: block;
      }

      .eyebrows {
        position: absolute;
        bottom: 0;
        left: 2rem;
        z-index: 1000;
        a {
          color: white;
        }
      }

      .wrapper {
        position: relative;
        height: 100%;
        
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 48, 108, 0.7);
          z-index: 1000;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        //aspect-ratio: 1.88;
      }
    }

    .layout {
      .eyebrows {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }

  &.alt-variant {
    .featured_content {
      flex-direction: column-reverse;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      .content {
        &.has-alt-variant {
          padding-bottom: 4rem;

          @include media-breakpoint-up(md) {
            padding-bottom: 0;
          }

          .media {
            padding: 0;

            @include media-breakpoint-up(md) {
              padding: 1rem;
            }
          }
        }
      }
    }
  }
  
  .has-mobile-variant {
    .media {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}

.featured_content {
  height: 100%;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;

  .content {
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;

    &:has(.media) {
      justify-content: flex-end;
    }
  }

  .copy {
    &.featured-content-copy {
      margin-bottom: 2rem;

      @include list-styles();

      ol li, ul li {
        font-size: clamp(1.6rem, 1.3vw, 2rem);
        color: $medium_blue;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: "Praktika ExtraBold";
        font-weight: 900;
        margin-bottom: 2rem;
        color: $dark_blue;
      }

      h1 {
        font-size: 4rem;
        line-height: 3.8rem;
      }

      h2 {
        font-size: 3.5rem;
        line-height: 3.6rem;
      }

      h3 {
        font-size: 3rem;
        line-height: 3.1rem;
      }

      h4 {
        font-size: 2.5rem;
        line-height: 2.6rem;
      }

      h5 {
        font-size: 2rem;
        line-height: 2.1rem;
      }

      h6 {
        font-size: 1.8rem;
        line-height: 1.9rem;
      }

      &.dark {
        h1, h2, h3, h4, h5, h6 {
          color: $white;
          font-family: "Praktika ExtraBold";
          font-weight: 900;
          margin-bottom: 2rem;
          
        }
        p, ol li, ul li {
          color: $light_aqua;
        }
      }

      h1 {
        @include praktika_text(
          "Praktika ExtraBold",
          clamp(4rem, 4vw, 5.5rem),
          900,
          normal,
          clamp(4.2rem, 4vw, 5.5rem)
        );
        letter-spacing: 0.02em;
        margin-bottom: 2rem;
        color: $dark_blue;
      }
      p {
        @include public_sans_text(
          clamp(1.6rem, 1.3vw, 2rem),
          500,
          normal,
          clamp(2.6rem, 1.9vw, 2.8rem)
        );
        color: $medium_blue;
      }
    }
  }

  .ctas {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 3.3rem;

    > div {
      max-height: 5.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .button {
      max-height: 5.25rem;
      @include public_sans_text(
        clamp(1.2rem, 1vw, 1.4rem),
        600,
        normal,
        1,
        uppercase
      );
      padding: 1.4rem 1.8rem;
      border-radius: 1rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &.partner {
        padding: 0.5rem 1.8rem;
        img {
          margin-right: 1.5rem;
        }
      }
    }
  }
  .media {
    padding: 1rem;
    width: 100%;
    position: relative;

    &.has_video {
      overflow: hidden;
      height: 100%;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 2rem;
      }

      // youtube
      iframe {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 2rem;
      }
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      //aspect-ratio: 1;
      border-radius: 2rem;
    }
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: 3rem;
    // padding-bottom: 10rem;
    
    .content {
      &.right_align {
        order: 1;
      }
    }
  }
}
