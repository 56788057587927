/**
    * Colors
    * -----------------------------------------------------------------------------
    * 
    *
    * @package Henry
*/
@use "sass:string";

$shadow_gray: #333132;
$stock_gray: #e1e1e1;
$medium_gray: #bcbec0;
$soft_gray: #efefea;
$light_gray: #f1f1f2;
$white: #ffffff;
$yellow: #ffd200;
$orange: #ff7a00;
$dark_blue: #00306c;
$medium_blue: #0054a6;
$blue: #3376b8;
$light_blue: #0090d2;
$light_aqua: #9ad1d4;
$dark_gray: #0d0d0d;
$pale_blue: #a7def7;
$search_background_blue: #D9E5F2;

$colors: (
  "shadow_gray": $shadow_gray,
  "stock_gray": $stock_gray,
  "medium_gray": $medium_gray,
  "soft_gray": $soft_gray,
  "light_gray": $light_gray,
  "white": $white,
  "yellow": $yellow,
  "orange": $orange,
  "dark_blue": $dark_blue,
  "medium_blue": $medium_blue,
  "blue": $blue,
  "light_blue": $light_blue,
  "light_aqua": $light_aqua,
  "transparent": transparent,
);

// font colors
// shadow_gray white dark_blue light_blue
$font_colors: "shadow_gray", "white", "dark_blue", "light_blue", "medium_blue", "light_aqua";
@mixin font_colors() {
  @each $color in $font_colors {
    .font_#{$color} {
      color: map-get($colors, $color);
    }
  }
}
@include font_colors();

// color schemes (used for buttons)
// border background  color

/**
    * Button schemes from the Featured Content module
    * -----------------------------------------------------------------------------
    * 
    * Referenced buttons:
    * - Eyebrow Buttons
    * - - Roofing
    * - - Siding
    * - CTAs
    * - - PRODUCT DETAILS
    * - - TALK TO A REP
*/
// eyebrows
// yellow white shadow_gray -- light theme
// medium_gray white shadow_gray  -- light theme
// light_blue white shadow_gray -- knowledge center

// yellow transparent dark_blue -- light background-image
// medium_gray transparent dark_blue - light background-image
// light_blue transparent dark_blue -- knowledge center

// yellow dark_blue white -- dark theme
// medium_gray dark_blue white -- dark theme
// light_blue dark_blue white -- knowledge center

// yellow transparent white -- dark theme (overlay) w/ background-image
// medium_gray transparent white -- dark theme (overlay) w/ background-image
// light_blue transparent white -- knowledge center
$color_schemes:
  "yellow white shadow_gray", "medium_gray white shadow_gray", "medium_gray white shadow_gray",
  "yellow transparent dark_blue", "medium_gray transparent dark_blue", "light_blue transparent dark_blue",
  "yellow dark_blue white", "medium_gray dark_blue white", "light_blue transparent white",
  "yellow transparent white", "medium_gray transparent white", "light_blue transparent white";

// ctas
// orange orange white
$color_schemes: append($color_schemes, "orange orange white");

/**
    * Button schemes from the Product Categories module
    * -----------------------------------------------------------------------------
    * 
*/
// light_blue light_blue white
$color_schemes: append($color_schemes, "light_blue light_blue white"); // Load More button

/**
    * Button schemes from the Knowledge Center Feed
    * -----------------------------------------------------------------------------
    * 
*/
// light_blue soft_gray soft_gray
$color_schemes: append($color_schemes, "light_blue soft_gray medium_gray");

/**
    * Mixin to generate styles
    * -----------------------------------------------------------------------------
    * 
    * @param {boolean} $createBorders - whether to create border styles
    * @param {boolean} $createBackgrounds - whether to create background styles
*/
@mixin generate_color_schemes($createBorders: true, $createBackgrounds: true) {
  $seen_borders: ();
  $seen_backgrounds: ();
  @each $scheme in $color_schemes {
    $scheme_colors: string.split($scheme, " ");

    $border-color: nth($scheme_colors, 1);
    $background-color: nth($scheme_colors, 2);

    @if $createBorders {
      @if not index($seen_borders, $border-color) {
        &.border_#{$border-color} {
          border: solid 2px map-get($colors, $border-color);
        }
        $seen_borders: append($seen_borders, $border-color);
      }
    }

    @if $createBackgrounds {
      @if not index($seen_backgrounds, $background-color) {
        &.bg_#{$background-color} {
          background-color: map-get($colors, $background-color);
        }
        $seen_backgrounds: append($seen_backgrounds, $background-color);
      }
    }
  }
}

/**
    * Mixin to generate styles for category tags
    * -----------------------------------------------------------------------------
    * 
    * @param {boolean} $createBorders - whether to create border styles
    * @param {boolean} $createBackgrounds - whether to create background styles
*/
@mixin generate_color_schemes_cat_tags($createBorders: true, $createBackgrounds: true) {
  $seen_borders: ();
  $seen_backgrounds: ();
  @each $scheme in $color_schemes {
    $scheme_colors: string.split($scheme, " ");

    $border-color: nth($scheme_colors, 1);
    $background-color: nth($scheme_colors, 2);

    @if $createBorders {
      @if not index($seen_borders, $border-color) {
        &.border_#{$border-color} {
          border: solid 2px map-get($colors, $border-color);

          &:hover {
            background-color: map-get($colors, $border-color);
          }
        }
        $seen_borders: append($seen_borders, $border-color);
      }
    }
  }
}
