@mixin list-styles {
    ul, ol {
        &:not(:last-child) {
            padding-bottom: 3rem;
        }
    }

    ul li, ol li {
        @include public_sans_text();
        font-size: 1.6rem;
        line-height: 2.3rem;
        font-weight: 500;
        color: $shadow_gray;

        a {
            font-size: 1.6rem;
            color: $light_blue;
            text-decoration: none;

            &:hover {
                color: $medium_blue;
            }
        }

        strong, em, u {
            font-size: 1.6rem;
        }

        strong {
            font-weight: inherit;
            color: $medium_blue;
        }
    }
}

@mixin rte-styles {
    padding: 0;

    &.two-cols {
        @include media-breakpoint-up(lg) {
            column-count: 2;
            column-gap: 10rem;
        }
    }

    & p {
        @include public_sans_text();
        font-size: 1.6rem;
        line-height: 2.3rem;
        font-weight: 500;
        color: $shadow_gray;

        &:not(:last-child) {
            padding-bottom: 3rem;

            @include media-breakpoint-down(lg) {
                padding-bottom: 2rem;
            }
        }

        strong, em, u {
            font-size: 1.6rem;
        }

        strong {
            font-weight: bold;
            color: $medium_blue;
        }

        a {
            @include public_sans_text();
            font-size: 1.6rem;
            line-height: 2.3rem;
            font-weight: 500;
            text-decoration: none;
            color: $light_blue;
            padding: 0;

            &:hover {
                color: $medium_blue;
            }

            &.font_white {
                color: $white;

                &.button {
                    text-transform: uppercase;
                    padding: 5px 15px;
                    font-size: 1.2rem;
                    color: $white;
                }
            }
        }
        // override
        a.arrow-style {
            @include public_sans_text();
            font-size: 1.4rem;
            line-height: 2.8rem;
            font-weight: 700;
            color: $light_blue;
            text-decoration: none;
            text-transform: uppercase;
            position: relative;
            padding-right: 20px;

            &::after {
                content: '';
                display: inline-block;
                width: 14px;
                height: 6px;
                background-image: url('/dist/images/link-arrow.svg');
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                right: 0;
                bottom: 5px;
                //top: 50%;
                //transform: translateY(-50%);
            }

            &:hover {
                color: $medium_blue;
            }
        }
    }

    @include list-styles();

    strong {
        @include public_sans_text();
        font-size: 1.6rem;
        line-height: 2.3rem;
        font-weight: 500;
        color: $shadow_gray;
    }

    & h1, h2, h3, h4, h5, h6 {
        @include proxima_nova_text();
        font-weight: 700;
        // font-size: 3.5rem;
        // line-height: 3.6rem;
        color: $medium_blue !important;

        &:not(:last-child) {
            padding-bottom: 3rem;

            @include media-breakpoint-down(lg) {
                padding-bottom: 2rem;
            }
        }

        @include media-breakpoint-up(lg) {
            padding-right: 3rem;
        }

        a {
            color: $light_blue;
            text-decoration: none;
            font-weight: inherit;
            font-size: inherit;

            &:hover {
                color: $medium_blue;
            }
        }
    }

    & h1 {
        font-size: 4.0rem;
        line-height: 3.8rem;
    }

    & h2 {
        // @include proxima_nova_text();
        font-weight: 700;
        font-size: 3.5rem !important;
        line-height: 3.6rem;
        // color: $medium_blue;
        // padding-right: 3rem;
        margin-bottom: 0 !important;
    }

    & h3 {
        font-size: 3.0rem;
        line-height: 3.1rem;
    }

    & h4 {
        font-size: 2.5rem;
        line-height: 2.6rem;
    }

    & h5 {
        font-size: 2.0rem;
        line-height: 2.1rem;
    }

    & h6 {
        font-size: 1.8rem;
        line-height: 1.9rem;
    }

    & span {
        font-size: unset;
    }
}

.copy-block, .hubspot-form-wrapper, .product.featured_content, .accordion {
  padding: 7rem 12rem;

  @include media-breakpoint-down(xl) {
    padding: 3rem 5rem;
  }

  @include media-breakpoint-down(md) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .content-wrapper, .content, .accordion-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;

    .title, .title *, .copy-title {
      @include proxima_nova_text();
      font-weight: 700;
      font-size: 3.5rem;
      line-height: 3.6rem;
      color: $medium_blue;
      padding: 0;
    }

    .body, .hubspot-form-description, .copy {
        @include rte-styles;
    }
  }

  //specific overrides for the 404 page
  &.page-404,
  &.page-error {
    .content-wrapper {
      .body {
        text-align: center;
        padding-right: 0;


        > * {
          padding-bottom: 1.5rem !important;
          padding-right: 0;
        }
    
        h1 {
          font-size: 12.5rem;
          line-height: 12rem;
          font-weight: 900;
          color: $dark_blue !important;

          @include media-breakpoint-down(lg) {
            font-size: 9.5rem;
            line-height: 7rem;
          }
        }
    
        h2 {
          @include public_sans_text();
          font-weight: 700;
          font-size: 3.0rem;
          line-height: 3rem;

          @include media-breakpoint-up(lg) {
            line-height: 5rem;
          }
        }

        h3 {
          @include public_sans_text();
          font-weight: 500;
          font-size: 2.4rem;
          line-height: 5rem;
        }

        a {
          @include public_sans_text();
          font-size: 2.4rem;
          color: $light_blue;
        }
      }
    }
  }
}

.copy-block {
    &.remove-top-padding {
        padding-top: 0;
    }

    &.remove-bottom-padding {
        padding-bottom: 0;
    }

    &.remove-left-padding {
        padding-left: 0;
    }

    &.remove-right-padding {
        padding-right: 0;
    }
}