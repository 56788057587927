.product-page {

  .blade.debug {
    height: 300px;
    width: 100%;
    border: 1px solid $dark_blue;
    background-color: $yellow;

    & * {
      font-size: 1.6rem;
      
    }
  }

  // breadcrumbss + social
  .top-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding-top: 3rem;
    padding-left: 12rem;
    padding-right: 12rem;

    @include media-breakpoint-down(xl) {
      padding-left: 5rem;
      padding-right: 5rem;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .breadcrumbs {
      margin: 0;
      padding: 0;

      .breadcrumbs-list {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 1.5rem;
        list-style: none;
        margin: 0;
        padding: 0;
        

        .breadcrumbs-item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          &:last-child {
            span {
              display: none;
            }
          }

          span {
            display: flex;
            flex: 1;

            img {
              width: 6px;
              height: 8px;
            }
          }

          .breadcrumbs-link {
            text-decoration: none;
            cursor: pointer;
            @include proxima_nova_text();
            font-weight: 500;
            font-size: 1.3rem;
            line-height: 2.8rem;
            color: $shadow_gray;
            padding-right: 1.5rem;
          }
        }
      }
    }



    .social-share {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 2rem;
      align-items: flex-end;

      .share-text {
        @include public_sans_text(1.3rem, 700, normal, 2.8rem);
        color: $shadow_gray;
      }

      ul {
        list-style: none;
        display: flex;
        gap: 2rem;
        margin: 0;
      }
    }
  }

}