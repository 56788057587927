#onetrust-pc-sdk {
  #ot-pc-content {
    font-size: 1.5rem !important;
  }
  .ot-acc-hdr {
    .ot-cat-header {
      font-size: 1.5rem !important;
    }
    
    .ot-always-active {
      font-size: 1.5rem !important;
    }
  }

  .ot-acc-txt {
    .ot-acc-grpdesc {
      font-size: 1.5rem !important;      
    }

    .ot-hlst-cntr {
      button {
        font-size: 1.5rem !important;
      }
    }
  }

  .ot-pc-footer {
    .ot-btn-container {
      button {
        font-size: 1.5rem !important;
      }
    }
  }
}