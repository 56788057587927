
.cool-roof-calculator {
  position: relative;
  padding: 7rem 0;
  display: flex;
  flex-direction: row;
  gap: 16rem;
  background-color: $dark_blue;

  @include media-breakpoint-down(xl) {
    padding: 3rem 0;
    gap: 0;

    flex-direction: column;
  }



  .left-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    flex: 1;


    .logo-wrapper {

      img {
        width: 100%;
      }
    }


    .heading {
      &, * {
        @include praktika_text('Praktika ExtraBold');
        font-size: 5.5rem;
        line-height: 5.5rem;
        font-weight: 900;
        color: $white;
      }
    }

    .subheading {
      &, * {
        @include public_sans_text();
        font-size: 2.0rem;
        font-weight: 500;
        line-height: 2.8rem;
        color: $light_aqua;
      }
    }

    .description {
      &, * {
        @include public_sans_text();
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.3rem;
        color: $white;
      }
    }
  }




  .right-content {
    display: flex;
    flex: 1;
    padding: 2rem 0;

    .button {
      @include public_sans_text();
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      line-height: 2.8rem;
      font-weight: 700;
      border-radius: 10px;
      text-transform: uppercase;
      width: fit-content;
      cursor: pointer;
      height: 100%;

      @include media-breakpoint-down(xl) {
        width: 100%;
        justify-content: center;
      }
    }


    .fields {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      &:not(.active) {
        display: none;
      }

      .top {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        @include media-breakpoint-down(xl) {
            flex-direction: column;
            padding-bottom: 2rem;
        }

        .form-group.square-footage {          
          position: relative; 
          
          input {
            // display: block;
            background: $pale_blue;
            border-radius: 10px;
            border: 1px solid $pale_blue;
            
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: $medium_blue;

            // width: 225px;
            height: 48px;

            // padding: 0 20px;
            // overflow-y: scroll;

            width: 100%;
            position: relative;
            padding: 0 2rem;


            &[type="number"] {
              & {
                -moz-appearance: textfield;
              }

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }              
            }

            &:focus-visible {
              border: none;
              outline: none;
            }

            &::placeholder {
              @include proxima_nova_text();
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 2.4rem;
              color: $medium_blue;
            }
            
          }
          &:after {
            content: '/ sq ft.';
            background-repeat: no-repeat;
            display: block;
            position: absolute;
            
            top: 50%;
            transform: translateY(-40%);
            right: 2rem;
            
            @include proxima_nova_text();
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: $dark_blue;
          }
        }


        .form-group.product-category,
        .form-group.substrate-type {
          position: relative;
          height: 48px;
          border-radius: 10px;

          // background: $pale_blue;
          // border-radius: 10px;
          // height: 48px;
          // width: 100%;
          // padding: 0 0 0 2rem;
          // position: relative;

          select {
            @include proxima_nova_text();
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: $medium_blue;
            background: $pale_blue;
            border: none;
            
            // position: absolute;
            // top: 0;
            // height: 100%;
            // width: 90%;

            height: 48px;
            width: 100%;
            padding: 0 0 0 2rem;
            position: relative;
            border-radius: 10px;
  
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
  
            &::-ms-expand {
              display: none;
            }
  
            &:focus-visible {
              border: none;
              outline: none;
            }
  
            option {
              padding: 0;
              margin: 0;
            }
  
            
          }

          &:after {
            content: '';
            background-repeat: no-repeat;
            background-image: url("/dist/images/calc-chevron.svg");
            display: block;
            position: absolute;
            
            top: 50%;
            transform: translateY(10%);
            right: 2rem;

            width: 12px;
            height: 8px;
          }
        }
        
      }


      .bottom {
        display: flex;
        gap: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-top: 3.4rem;

        @include media-breakpoint-down(xl) {
            flex-direction: column;
        }

        .fields-req {
          @include proxima_nova_text();
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: $white;
        }


      }
    }


    .result {
      display: flex;
      flex-direction: column;


      &:not(.active) {
        display: none;
      }

      .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;

        @include media-breakpoint-down(xl) {
            flex-direction: column;
        }

        .buckets {
          background-color: $pale_blue;
          border-radius: 15px;

          p {
            @include proxima_nova_text();
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 3.6rem;
            color: $dark_blue;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            padding: 1rem 4.5rem;
          }

          span.total-buckets {
            @include proxima_nova_text();
            font-weight: 800;
            font-size: 13rem;
            line-height: 11rem;
            color: $medium_blue;
          }

          span.text-buckets {
            @include proxima_nova_text();
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 3.6rem;
            color: $dark_blue;
          }
        }
        .results-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 2rem;

          .based-on, .summary {
            * {
              @include proxima_nova_text();
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 2.4rem;
              color: $white;
            }
          }

          .disclaimer {
            &, * {
              @include proxima_nova_text();
              font-weight: 400;
              font-size: 1.0rem;
              line-height: 1.4rem;
              color: $white;
              font-style: italic;
            }
          }
        }
      }

      .bottom {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 1.5rem;
        cursor: pointer;
        margin-top: 3.4rem;

        @include media-breakpoint-down(xl) {
            flex-direction: column;
        }
      }
    } 
  }

}
