/**
    * Layout
    * -----------------------------------------------------------------------------
    * Various global layout styles.
    * @package Henry
*/

// layout vars
$max_content_width: 168rem; //144rem

// base
html {
  width: 100%;
  @include media-breakpoint-down(xl) {
    overflow-x: hidden;
  }

  &.prevent-overflow {
    overflow: hidden;
  }
}
body {
  overflow-x: hidden;
  overscroll-behavior: none;
  padding: 0;
  margin: 0 auto;
}

// lateral alignments
main#main_container,
#footer {
  max-width: 200rem;
  margin: 0 auto;


  // colors
  background-color: $stock_gray;
  .outer_wrapper {
    background-color: $white;
  }
  //

  
  .outer_wrapper {
    max-width: 168rem;
    margin: 0 auto;

    .inner_wrapper {
      max-width: $max_content_width;
      width: 100%;
      margin: 0 auto;
    }

    .blade, .footer {
      padding-left: 12rem;
      padding-right: 12rem;

      @include media-breakpoint-down(xl) {
        padding-left: 5rem;
        padding-right: 5rem;
      }

      @include media-breakpoint-down(md) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
}

// vertical alignment
main#main_container {
  //mobile fix
  margin-top: -20px;
  border-top: 1px solid $medium_gray;

  @include media-breakpoint-up(lg) {
    //pulling from debug.scss, not sure if this is required.
    margin-top: 20px;
  }

  .outer_wrapper > .inner_wrapper > * {
    &:first-of-type {
      z-index: 8;
      position: relative;
    }
  }
}

img {
  font-size: 1.25rem;
  // max-width: 50%;
}