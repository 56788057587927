// .product-page {
  .product-navigation {
    .container-fluid .row > * {
      margin: 0;
      padding: 0;
    }

    #product-navbar {
      & {
        background-color: $medium_blue;
        padding: 3rem 12rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 3.5rem;
        
        @include media-breakpoint-down(xl) {
          padding-left: 2rem;
          padding-right: 2rem;
        }

        @include media-breakpoint-down(xl) {
          flex-direction: column;
          align-items: flex-start;
          gap: 2rem;
        }
    
    
        &.sticky {
          position: fixed;
          top: 0;
          max-width: 168rem;
          min-width: 144rem;
          z-index: 999;

          @include media-breakpoint-down(xl) {
            max-width: 100%;
            min-width: auto;
          }
        }


        .nav-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 6rem;

          padding-left: 12rem;
          padding-right: 12rem;

          > * {
            width: auto;
          }

          @include media-breakpoint-down(xl) {
            padding-left: 2rem;
            padding-right: 2rem;

            flex-direction: column;
            align-items: flex-start;
            gap: 2rem;
          }
        }

        .heading {
          padding: 0;
          white-space: wrap;
          max-width: 300px;
          min-width: 200px;

          @include media-breakpoint-down(xl) {
            max-width: none;
            min-width: auto;
          }

          &, & * {
            @include proxima_nova_text();
            font-weight: 600;
            font-size: 2.0rem;
            line-height: 2.5rem;
            color: $white;
          }
          
        }

        .nav-links-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 4rem;
          list-style-type: none;
          margin: 0;
          padding: 0;

          @include media-breakpoint-up(xl) {
            //justify-content: space-between;
            flex-grow: 1;
          }
          
          @include media-breakpoint-down(xl) {
            overflow-x: auto;
            flex-wrap: nowrap;
            // hide scrollbars in overflow auto
            scrollbar-width: none;
            -ms-overflow-style: none;
            &::-webkit-scrollbar {
              display: none;
            }

            max-width: 100%;
            min-width: auto;
          }
          
        }
    
        .nav-item {
          padding: 0;
          margin: 0;
          height: max-content;
          flex: 0 0 auto;

          @include media-breakpoint-up(xl) {
            &:not(:last-child) {
              padding-right: 6rem;
            }
          }
    
          a {
            text-decoration: none;
            background-color: transparent;
            border: none;
            padding: 0;
            @include praktika_text();
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 2rem;
            color: $white;
            letter-spacing: 0.25px;
    
            &:after {
              content: '';
              display: block;
              width: 100%;
              height: 4px;
              background-color: transparent;
              border-radius: 5px;
              margin: 0.35rem auto 0;
            }
    
            &.active {
              color: $light_aqua;
              background-color: transparent;
              border: none;
              // border-bottom: 4px solid $light_aqua;
              // padding-bottom: 0.35rem;
    
              &:after {
                background-color: $light_aqua;
              }
            }
          }
        }
      }
    }
  }
// }