.search-listing {
  display: inline-block;
  width: 100%;
  padding-top: 8rem;
  padding-bottom: 8rem;

  @include media-breakpoint-down(xl) {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }

  .filters {
    @include media-breakpoint-down(xl) {
        order: 2;
    }

    @include media-breakpoint-up(xl) {
        width: 25%;
        float: left;
    }

    .filters-wrapper {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      position: relative;


      .desktop-header {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 2rem;
        border-bottom: 1px solid $medium_gray;

        @include media-breakpoint-down(xl) {
          display: none;
        }


        .title {
          display: flex;
          flex: 0 0 50%;

          @include public_sans_text();
          font-size: 1.8rem;
          line-height: 2.52rem;
          font-weight: 700;
          color: $dark_blue;

          @include media-breakpoint-down(xl) {
            display: none;
          }
        }


        .reset {
          @include public_sans_text();
          font-size: 1.4rem;
          line-height: 2.3rem;
          font-weight: 500;
          color: $light_blue;

          // flex: 0 0 50%;
          margin-left: auto;
        }
        
        .showing {
          display: flex;
          flex: 0 0 100%;
          padding-top: 1.5rem;

          &, & * {
            @include public_sans_text();
            font-size: 1.5rem;
            line-height: 2.25rem;
            font-weight: 400;
            color: $shadow_gray;
          }          
        }

      }


      .mobile-header {
        display: none;

        @include media-breakpoint-down(xl) {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        .search-filters-button {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          gap: 1rem;

          border-radius: 10px;
          height: 40px;
          text-transform: uppercase;
          padding: 0 1.8rem;
          background-color: $light_blue;
          color: $white;
          border: none;
          position: relative;
          
          img {
            width: 12px;
            height: 8px;
          }
          
          &, & span {
            @include public_sans_text();
            font-size: 1.4rem;
            font-weight: 700;  
            text-transform: uppercase;
            color: $white;
          }
        }

        .showing {
          @include public_sans_text();
          font-size: 1.5rem;
          line-height: 2.25rem;
          font-weight: 400;
          color: $shadow_gray;
        }
      }

      .flyout-content {
        display: none;

        padding-bottom: 2rem; 
        border-bottom: 1px solid $medium_gray;
        
        .title {
          display: flex;
          flex: 0 0 50%;

          @include public_sans_text();
          font-size: 1.8rem;
          line-height: 2.52rem;
          font-weight: 700;
          color: $dark_blue;

          @include media-breakpoint-down(xl) {
            display: none;
          }
        }

        .see-results {
          @include public_sans_text();
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          font-weight: 700;
          padding: 0 18px;
          border-radius: 10px;
          height: 40px;
          text-transform: uppercase;
          text-decoration: none;
          background-color: $light_blue;
          color: $white;
          border: none;
        }

        .filters-close {
          position: absolute;
          right: 4rem;
          top: 3rem;
        }
      }



      .filter-section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 2rem;

        &.noresults {
          display: none;
        }
        
        @include media-breakpoint-down(xl) {
          display: none;
          
        }
        
        // &:first-of-type {
        //   padding-top: 2rem;
        //   border-top: 1px solid $medium_gray;
        // }

        &:not(:last-child) {
          border-bottom: 1px solid $medium_gray;
        }


        // open state
        .filter-title {
          @include public_sans_text();
          font-size: 1.8rem;
          line-height: 2.7rem;
          font-weight: 700;
          color: $dark_blue;
          position: relative;
          text-decoration: none;

          &:after {
            content: '';
            background-image: url('/dist/images/vector.svg');
            // transform: rotate(180deg) scale(1.75) translate(3px, 2px);
            width: 12px;
            height: 7px;
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        ul.filter-options {
          list-style-type: none;
          padding: 0;
          margin: 0;

          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          align-items: self-start;
          justify-content: center;

          li {

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 0.5rem;

            input[type="radio"] {
              margin-right: 10px;
              appearance: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              width: 16px;
              height: 16px;
              border: 1px solid $medium_blue;
              border-radius: 50%;
              outline: none;
              cursor: pointer;
              position: relative;

              &:checked {
                background-color: $medium_blue;
                border-color: $medium_blue;
                position: relative;

                &::before {
                  content: '';
                  display: block;
                  width: 6px;
                  height: 6px;
                  margin: 3px;
                  border-radius: 50%;
                  background-color: white;
                  position: absolute;
                  top: 1px;
                  left: 1px;


                  @include media-breakpoint-down(xl) {
                    margin: 2.5px;
                  }

                } // :before
              } // :checked
            } // input

            label {
              cursor: pointer;
              @include public_sans_text();
              font-size: 1.6rem;
              line-height: 2.8rem;
              font-weight: 400;
              color: $shadow_gray;

              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            } // label
          } //li
        } //ul



        // close state
        &.close {
          .filter-title {
            &:after {
              transform: rotate(180deg);
            }
          }

          ul.filter-options {
            display: none;
          }
        }
      } //.filter-section

    }
    
    // mobile / flyout open state display settings
    &.mobile-expanded {
      position: fixed;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 4;

      .filters-wrapper {
        height: 100%;
        overflow-y: scroll;
        background-color: $white;
        padding: 8rem 4rem;

        .mobile-header {
          display: none;           
        }

        .flyout-content {
          &, & * {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }
        }   

        .filter-section {
          display: flex;
        }   
      }
    }
  }

  .text-search-container {
    @include media-breakpoint-down(xl) {
        order: 1;
    }

    @include media-breakpoint-up(xl) {
        width: 75%;
        padding-left: 5rem;
        float: right;
    }

    .search-bar {
      display: flex;
      align-items: center;
      background: rgba(0, 84, 166, 0.15);
      height: 75px;
      border-radius: 33px;
      position: relative;

      form {
        width: 100%;
        z-index: 999;
      }

      .search-input-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .search-input {
          @include public_sans_text();
          font-size: 3rem;
          line-height: 2rem;
          font-weight: 400;
          color: $medium_blue;
          border: none;
          outline: none;
          background: transparent;
          width: 90%;
          padding-left: 2rem;
          display: flex;


          &::placeholder {
            @include public_sans_text();
            font-size: 3rem;
            line-height: 2rem;
            font-weight: 400;
            color: $medium_blue;
          }
        }
      }

      .btn-submit {
        background-image: url('/dist/images/search-icon-blue.svg');
        background-repeat: no-repeat;
        background-size: 3rem;
        width: 3rem;
        height: 3rem;
        margin-right: 2rem;

        &:focus {
          outline: 1px solid $medium_blue;
        }
      }

      ul.ui-autocomplete li div {
        font-size: 2rem;
      }

      @include media-breakpoint-down(xl) {
        height: 45px;


        .search-input-wrapper {
          .search-input {
            font-size: 2rem;
            line-height: 2rem;

            &::placeholder {
              font-size: 2rem;
              line-height: 2rem;
            }
          }

          .btn-submit {
            background-size: 2rem;
            width: 2rem;
            height: 2rem;
          }
        }
      }
    } //search-bar

    .results-summary {
      padding: 4rem 0;

      &, > * {
          @include proxima_nova_text();
          color: $medium_blue;
          font-size: 3.5rem;
          line-height: 3.6rem;
          font-weight: 700;
      }

      @include media-breakpoint-down(xl) {
        padding: 2rem 2rem 0;
        text-align: center;

        &, > * {
            font-size: 2.5rem;
            line-height: 3.0rem;
        }
      }

    }
  }

  .search-container {
    @include media-breakpoint-down(xl) {
        order: 3;
    }

    @include media-breakpoint-up(xl) {
        width: 75%;
        padding-left: 5rem;
        float: right;
    }

    .results-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 3rem;


      @include media-breakpoint-down(xl) {
        gap: 4rem;
      }


      .result-item {
        display: flex;
        flex-direction: row;
        gap: 2rem;

        a {
          text-decoration: none;
          display: flex;
          flex-direction: row;
          gap: 2rem;
        }

        img {
            width: 80px;
            height: 80px;
            border-radius: 10px;
            aspect-ratio: 1;
            -o-object-fit: cover;
            object-fit: cover;
        }

        .result-no-image {
            background: transparent;
            width: 80px;
            height: 80px;
        }

        .result-content {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          .result-title {
            @include public_sans_text();
            font-size: 1.8rem;
            line-height: 2.4rem;
            font-weight: 500;
            color: $medium_blue;
            padding: 0;
            margin: 0;

            @include media-breakpoint-down(xl) {
              font-size: 1.4rem;
              line-height: 2.4rem;
            }
          }

          .result-description {

            &, & p {
              @include public_sans_text();
              font-size: 1.4rem;
              line-height: 2.0rem;
              font-weight: 500;
              color: $shadow_gray;

              @include media-breakpoint-down(xl) {
                font-size: 1.2rem;
                line-height: 1.8rem;
              }
            }
          }
        }
      }
    }

    .load-more {
      @include public_sans_text();
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
      font-weight: 700;
      padding: 0 18px;
      border-radius: 10px;
      height: 40px;
      text-transform: uppercase;
      text-decoration: none;
      background-color: $light_blue;
      color: $white;
      border: none;
      padding: 0 7rem;
      margin: 7rem auto;

      &.hidden {
        display: none;
      }

      @include media-breakpoint-up(xl) {
        &:disabled {
          background-color: $white;
          color: $light_blue;
          border: 2px solid $light_blue;
          &:hover {
            background-color: $white;
            color: $light_blue;
            border: 2px solid $light_blue;
          }
        }

        &:hover {
          background-color: $white;
          color: $light_blue;
          border: 2px solid $light_blue;
        }
      }

      @include media-breakpoint-down(xl) {
        padding: 0;
        width: 100%;
      }
    }

    .no-results {
        &.hidden {
                display: none;
            }

        h2 {
            @include praktika_text();
            font-size: 3rem;
            color: $dark_blue;
            margin-bottom: 2rem;
        }
        p {
            @include public_sans_text();
            font-size: 1.6rem;
        }
    }
  }
}

.filters {
  &.mobile-expanded {
    &+.text-search-container {
      #search-form {
        z-index: 0;
      }
    }
  }
}