.two-column-content-block {
  padding: 7rem 0;
  @include media-breakpoint-down(xl) {
    padding: 3rem 0;
  }

  .content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;

    &.reverse {
      @include media-breakpoint-up(xl) {
        flex-direction: row-reverse;
      }
    }

    &.narrow {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @include media-breakpoint-down(xl) {
      flex-direction: column;

      &.reverse {
        flex-direction: column-reverse;
      }
    }

    // columns
    .item-wrapper {
      padding-left: 0;
      padding-right: 0;
      flex: 1;

      > div {
        // height: 100%;
        > .image-link-block {
          height: 100%;
          > .container-fluid {
            height: 100%;
            > .row {
              height: 100%;
              width: 100%;
              > a {
                height: 100%;
                .image-container {
                  height: 100%;
                  > img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      }

      // nested Image ( Image Link Block)
      .image-link-block {
        a.item {
          padding: 0;
        }

        img {
          border-radius: 20px;
          max-width: 100%;
        }
      }

      // nested Copy Block
      .copy-block {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;

        .content-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 2rem;

          .title,
          .title *,
          .copy-title {
            @include proxima_nova_text();
            font-weight: 700;
            font-size: 3.5rem;
            line-height: 3.6rem;
            color: $medium_blue;
            padding: 0;
          }

          .body {
            padding: 0;
            padding-right: 3rem;

            & p {
              @include public_sans_text();
              font-size: 1.6rem;
              line-height: 2.3rem;
              font-weight: 500;
              color: $shadow_gray;

              a {
                @include public_sans_text();
                font-size: 1.6rem;
                line-height: 2.3rem;
                font-weight: 500;
                text-decoration: none;
                color: $light_blue;
                padding: 0;

                &:hover {
                  color: $medium_blue;
                }
                &.button {
                  color: $white;
                  font-size: 1.2rem;
                  padding: 0 18px;
                  border-radius: 10px;
                  height: 40px;
                  text-transform: uppercase;
                  width: fit-content;
                  margin: 2rem 0;
                  
                  &:hover {
                    color: $white!important;
                  }
                }
              }

              // override
              a.arrow-style {
                @include public_sans_text();
                font-size: 1.4rem;
                line-height: 2.8rem;
                font-weight: 700;
                color: $light_blue;
                text-decoration: none;
                text-transform: uppercase;
                position: relative;
                padding-right: 20px;

                &::after {
                  content: "";
                  display: inline-block;
                  width: 14px;
                  height: 6px;
                  background-image: url("/dist/images/forward-arrow-default-d.svg");
                  background-size: contain;
                  background-repeat: no-repeat;
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);

                  @include media-breakpoint-down(xl) {
                    background-image: url("/dist/images/forward-arrow-default-m.svg");
                  }
                }

                &:hover {
                  color: $medium_blue;

                  &::after {
                    background-image: url("/dist/images/forward-arrow-hover-d.svg");
                  }
                }
              }
            }

            & h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              @include proxima_nova_text();
              font-weight: 700;
              color: $medium_blue;
              padding-right: 3rem;
            }

            & h1 {
              font-size: 4rem;
              line-height: 3.8rem;
            }

            & h2 {
              font-size: 3.5rem;
              line-height: 3.6rem;
            }

            & h3 {
              font-size: 3rem;
              line-height: 3.1rem;
            }

            & h4 {
              font-size: 2.5rem;
              line-height: 2.6rem;
            }

            & h5 {
              font-size: 2rem;
              line-height: 2.1rem;
            }

            & h6 {
              font-size: 1.8rem;
              line-height: 1.9rem;
            }

            strong {
              @include public_sans_text(
                $font-size: 1.6rem,
                $font-weight: 700,
                $line-height: 2.3rem
              );
              color: $medium_blue;
            }
          }
        }
      }

      // nested Button block
      .button {
        @include public_sans_text();
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 0 18px;
        border-radius: 10px;
        height: 40px;
        text-transform: uppercase;
        width: fit-content;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }

    @include media-breakpoint-down(xl) {
      .item-wrapper:has(.image-link-block):has(+ .item-wrapper .copy-block) {
        // background-color: hotpink; //debug
      }
      &.reverse {
        .item-wrapper:has(.image-link-block):has(+ .item-wrapper .copy-block) {
          // background-color: chartreuse; //debug
          order: 1;
        }
      }
      .item-wrapper:has(.copy-block):has(+ .item-wrapper .image-link-block) {
        // background-color: red; //debug
        order: 1;
      }
      &.reverse {
        .item-wrapper:has(.copy-block):has(+ .item-wrapper .image-link-block) {
          // background-color: goldenrod; //debug
          order: -1;
        }
      }
    }
  }

  &.dark {
    background-color: $dark_blue;
    .title,
    .title *,
    .copy-title {
      color: $light_aqua !important;
    }

    .body {
      & p {
        color: $white !important;
        a {
          &:hover {
            color: $light_aqua !important;
          }
        }

        strong {
          color: $light_aqua !important;
        }
      }
      & ul, & li {
        color: $white !important;
        
        a {
          font-size: 1.6rem;
          color: $light_blue;
          text-decoration: none;
          
          &:hover {
            color: $light_aqua;
          }
        }

        strong {
          color: $light_aqua !important;
        }
      }
      & h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $light_aqua !important;
      }
    }
  }
}
