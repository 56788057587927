ul.ui-autocomplete {
    z-index: 8;
    background: #D9E5F2;
    color: $dark_blue;
    list-style: none;
    cursor: pointer;
    margin: 0;
    padding: 5px;
    position: absolute !important;
    top: 20px !important;
    left: 0 !important;
    padding: 1rem 3rem;
    width: 100% !important;
    border-bottom-left-radius: 33px;
    border-bottom-right-radius: 33px;

    @include media-breakpoint-up(xl) {
        &:not(#ui-id-2) {
            top: 47px !important;
        }

        padding: 4rem 3rem;
    }

    li {
        margin: 0;
        padding: 10px 5px;
        position: relative;

        &:hover {
            color: $light_blue;
        }

        div {
            font-size: 1.5rem;
            //margin-right: 3rem;

            @include media-breakpoint-up(xl) {
                font-size: 1.4rem;
            }
        }

        &:before {
            content: '';
            background-image: url('/dist/images/autocomplete-search-icon.svg');
            background-repeat: no-repeat;
            display: block;
            position: absolute;
            width: 1.8rem;
            height: 1.8rem;
            top: 1.5rem;
            left: -1.5rem;
        }
    }
}

.search-wrapper {
    &.desktop {
        #ui-id-1 {
            top: 20px !important;
        }
    }
}

.ui-helper-hidden-accessible {
    display: none;
}