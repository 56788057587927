.systems-bar-block {
  background-color: $medium_blue;
  color: $white;
  padding: 3rem 0;

  .heading-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    padding: 5rem 2rem 5rem 0;

    @include media-breakpoint-down(xl) {
      padding: 2rem 0;
    }


    .heading {
      &, & p, * {
        @include proxima_nova_text();
        font-size: 3rem;
        font-weight: 700;
        line-height: 2.9rem;
      }
      
    }
  
    .subheading {
      
      &, & p, * {
        @include public_sans_text();
        color: $white;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 2.4rem;
      }
      
    }
  }


  .items-wrapper {
    padding: 2rem 0 2rem 3rem;

    // module breaks into two rows
    @include media-breakpoint-down(xl) {
      padding-left: 0;
    }

    .grid-container {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0;

      @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-breakpoint-down(md) {
        grid-template-columns: repeat(1, 1fr);
      }

      > * {
        @include media-breakpoint-up(lg) {
          padding: 1.45rem;
        }

        // 3 columns
        @include media-breakpoint-up(lg) {
          border-bottom: 2px solid $blue;
          border-left: 2px solid $blue;

          &:nth-child(3n) {
            border-right: 2px solid $blue;
          }

          &:nth-child(3n+1) {
            border-left: 2px solid $blue;
            // border-left: none;
          }

          &:nth-last-child(-n+3) {
            border-bottom: none;
          }
        }

        // 2 columns
        @include media-breakpoint-down(lg) {
          border-bottom: 2px solid $blue;
          border-right: 2px solid $blue;

          &:nth-child(2n) {
            border-right: none;
        }

          &:nth-last-child(-n+2) {
            border-bottom: none;
          }
        }

        @include media-breakpoint-down(md) {
          border: none;
          
          &:nth-child(1) {
            border-top: 2px solid $blue;
          }

          &:nth-child(n):not(:last-child) {
            border-bottom: 2px solid $blue;
          }
        }

      }
    }





    .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 2rem 1rem;
      text-decoration: none;

  
      @include media-breakpoint-down(xl) {
        padding: 0.75rem 0;
        gap: 1rem;
      }


      .type {
        &, & p, * {
          @include public_sans_text();
          font-weight: 700;
          font-size: 1rem;
          line-height: 1.6rem;
          text-transform: uppercase;
          color: $white;
        }
      }

      .system {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 1.75rem;
      }
  
      .item-text {
        &, & p, * {
          @include praktika_text('Praktika Bold');
          font-size: 2.5rem;
          font-weight: 700;
          line-height: 2.6rem;
          color: $light_aqua;
    
          // @include media-breakpoint-down(xl) {
          //   font-size: 2.5rem;
          //   font-weight: 700;
          //   line-height: 2.6rem;
          // }
        }
      }

      .item-image {
        width: 55px;
        height: auto;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
  
    }
  }
}