.timeline-block {
  background-color: $light_gray;

  @include media-breakpoint-up(xl) {
    padding-right: 0 !important;
  }

  &.light {
    background-color: $white;
    .timeline-inner-wrapper {
      background-color: $white;
    }
  }

  .timeline-inner-wrapper {
    background-color: #f1f1f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding-top: 7rem;
    padding-bottom: 7rem;

    @include media-breakpoint-down(xl) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    @include media-breakpoint-up(xl) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
  }

  .heading-wrapper {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
    justify-content: center;

    @include media-breakpoint-up(xl) {
      margin-left: -6rem;
    }

    .heading,
    .heading * {
      @include praktika_text("Praktika ExtraBold");
      font-size: 5.5rem;
      letter-spacing: -0.03rem;
      line-height: 1;
      color: $dark_blue;

      @include media-breakpoint-up(xl) {
        font-size: 4rem;
      }
    }

    .subheading {
      & p {
        @include public_sans_text();
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.3rem;
        color: $shadow_gray;
        width: calc(50% + 3rem);

        @include media-breakpoint-down(xl) {
          font-size: 1.4rem;
          line-height: 2rem;
          width: 100%;
        }
      }
    }
  }

  .timeline-container {
    padding-left: 0;
    padding-right: 0;

    // desktop gets visible above xl / 1200px
    &.desktop {
      width: 100%;
      max-width: 1440px; //1200px; (henwr-1147)
      margin: auto;
      position: relative;

      img.exclude {
        padding-right: 8px;
        position: absolute;
        bottom: calc(50% - 7.5px);
        transform: translateX(calc(-100%));
      }

      .slick-track {
        display: flex;
        flex-direction: row;
      }

      $constant_height: 172px; // 180px (henwr-1147)

      .timeline {
        width: 100%;
        position: relative;

        .milestone {
          width: calc(100% / 4);
          flex: 0 0 auto;
          height: auto;
          min-width: 300px;

          .milestone-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            padding: 2.5rem 8px 2.5rem 0;

            .milestone-image {
              height: $constant_height;
              flex: none;

              img {
                height: $constant_height;
                width: $constant_height;
                object-fit: cover;
                border-radius: 10px;
              }
            }

            .bar-wrapper {
              flex: 1;
              width: 100%;
              overflow: hidden;
              padding-top: 2rem;
              padding-bottom: 2rem;
            }

            .date-description {
              min-height: $constant_height;
              height: $constant_height;
              position: relative;
              flex-grow: 1;

              display: flex;
              flex-direction: column;
              gap: 1.5rem;
              justify-content: flex-start;

              .milestone-date {
                @include praktika_text("Praktika Bold");
                font-size: 2.4rem;
                color: $medium_blue;
              }

              .milestone-description {
                &,
                & p {
                  @include public_sans_text();
                  font-size: 1.6rem;
                  font-weight: 500;
                  line-height: 2.4rem;
                  color: $dark_blue;
                }

                p {
                  max-width: 90%;
                }
              }
              .milestone-description p {
                @include public_sans_text();
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 2.4rem;
                color: $dark_blue;
              }
            }
          }

          &:nth-child(2n) {
            .milestone-content {
              flex-direction: column-reverse;

              .date-description {
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }

    // mobile gets visible below xl / 1200px
    &.mobile {
      position: relative;
      overflow: hidden;

      // slick track
      .slick-track {
        display: flex;
        flex-direction: column;
      }

      .timeline {
        width: 100%;
        position: relative;

        .slick-list {
          margin: 0 auto;
        }

        .milestone {
          .milestone-content {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            text-align: center;
            position: relative;
            gap: 1rem;
            padding-bottom: 8px;

            .milestone-image {
              height: 100px;
              width: 100px;
              flex: none;

              img {
                // height: 100px;
                // width: 100px;
                width: 100%;
                height: auto;
                object-fit: cover;
                aspect-ratio: 1;
              }
            }

            .date-description {
              position: relative;
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              gap: 1.5rem;
              align-items: flex-start;
              padding-right: 0.75rem;

              .milestone-date {
                @include praktika_text("Praktika Bold");
                font-size: 2.4rem;
                color: $medium_blue;
              }

              .milestone-description,
              .milestone-description p {
                @include public_sans_text();
                font-size: 1.6rem;
                font-weight: 500;
                color: $dark_blue;
                text-align: left;
                font-size: 1.4rem;
                line-height: 2.1rem;
              }
            }
          }
        }
      }
    }
  }

  // arrows
  .timeline-desktop-arrows,
  .timeline-mobile-arrows {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;

    .timeline-arrow {
      cursor: pointer;
      background: $medium_blue;
      padding: 5px 10px;
      font-size: 24px;
      user-select: none;
      z-index: 1;
    }

    .left-arrow {
      color: $white;
      background: $medium_blue;
      border-radius: 50px;

      @include media-breakpoint-up(xl) {
        margin-left: -3rem;
      }

      &.slick-disabled {
        background-color: rgba($medium_blue, 0.25);
      }
    }

    .right-arrow {
      color: $white;
      background: $medium_blue;
      border-radius: 50px;

      @include media-breakpoint-up(xl) {
        margin-right: -3rem;
      }

      &.slick-disabled {
        background-color: rgba($medium_blue, 0.25);
      }
    }
  }

  .timeline-desktop-arrows {
    @include media-breakpoint-up(xl) {
      padding-right: 12rem;
    }
  }
}
