
  .product-details {
    padding: 7rem 0;
    @include media-breakpoint-down(xl) {
      padding: 3rem 0;
    }


    display: flex;
    flex-direction: row;
    gap: 4rem;

    @include media-breakpoint-down(xl) {
      flex-direction: column;
    }

    > * {
      flex: 1 1 50%;
    }

    .left-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      //gap: 4rem;

      .title,
      .title * {
        @include proxima_nova_text();
        font-weight: 700;
        font-size: 3.5rem;
        line-height: 3.6rem;
        color: $medium_blue;
        padding: 0;
      }

      .title {
        padding-bottom: 2rem;
      }

      .body {
        @include rte-styles;
        padding-right: 3rem;

        p {
            padding-bottom: 3rem;

            @include media-breakpoint-down(lg) {
                padding-bottom: 2rem;
            }
        }

        ul.sku-list {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
        }
      }

      .button {
        max-height: 5.25rem;
        @include public_sans_text(
          clamp(1.2rem, 1vw, 1.4rem),
          600,
          normal,
          1,
          uppercase
        );
        padding: 1.4rem 1.8rem;
        border-radius: 1rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &.partner {
          padding: 0.5rem 1.8rem;
          img {
            margin-right: 1.5rem;
          }
        }
      }
    }

    // right
    .right-col {
      display: flex;

      ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        padding: 0;
        gap: 3rem;
        width: 100%;

        li {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 2rem;
          width: 100%;

          &:not(:last-child) {
            border-bottom: 1px solid $medium_gray;
            padding-bottom: 3rem;
          }

          img {
            height: 65px;
            width: 65px;
            object-fit: cover;
          }

          .copy {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;

            .heading {
              @include public_sans_text();
              font-size: 1.8rem;
              line-height: 2.3rem;
              font-weight: 500;
              color: $medium_blue;
            }

            .subheading {
              @include public_sans_text();
              font-size: 1.4rem;
              line-height: 1.8rem;
              font-weight: 500;
              color: $shadow_gray;
            }
          }
        }
      }
    }
  }