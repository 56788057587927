.stats-block {
  padding-top: 6rem;
  padding-bottom: 6rem;
  
  .stats-block-top,
  .stats-block-stats-items-block {
    display: flex;
  }

  .stats-block-top {
    justify-content: center;
    align-items: flex-start;

    flex-direction: column;

    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }

    .heading,
    .subheading {
      flex-basis: 50%;
    }

    .heading {
      font-family: Praktika ExtraBold;
      font-size: 4rem;
      letter-spacing: -0.03rem;
      line-height: 1;
      color: $dark_blue;

      @include media-breakpoint-up(xl) {
        padding-right: 3rem;
        font-size: 5.5rem;
      }
    }

    .subheading {
      padding-top: 2rem;

      @include media-breakpoint-up(xl) {
        padding-left: 3rem;
        padding-top: 0;
      }

      p, p span {
        @include public_sans_text();
        font-size: 1.6rem;
        line-height: 28px;
        color: $medium_blue;

        @include media-breakpoint-up(xl) {
          font-size: 2rem;
        }
      }

    }
  }

  .stats-block-stats-items-block {
    justify-content: space-evenly;
    margin-top: 6rem;
    flex-direction: column;

    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }

    .stats-item-block {
      border-bottom: 1px solid $medium_gray;
      padding: 2rem 0;
      margin: 0 2rem;
      
      &:nth-child(1) img {
        width: 109px;
      }
  
      &:nth-child(2) img {
        width: 80px;
      }
  
      &:nth-child(3) img {
        width: 68px;
      }
      
      @include media-breakpoint-up(lg) {
        padding: 0;
        margin: 0;
        width: calc(100% * 1/3);

        &:nth-child(1) {
          margin-left: 2rem;
        }
  
        &:nth-child(2) {
          margin: 0 8rem;
        }
  
        &:nth-child(3) {
          margin-right: 2rem;
        }
      }

      .stats-item-row {
        &.top {
          .small-title {
            @include public_sans_text();
            font-size: 1.6rem;
            line-height: 24px;
            color: $dark_blue;
            font-weight: 400;
          }
        }

        &.bottom {
          display: flex;
          justify-content: space-between;
          // padding-top: 2.4rem;
          // padding-bottom: 2.4rem;
          // gap: 3rem;
          align-items: center;
          height: calc(100% - 24px);

          .title {
            @include praktika_text('Praktika Bold');
            font-size: 8rem;
            line-height: 112px;
            width: auto;
            color: $medium_blue;
          }

          .image-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            
          }

          img {
            // width: auto;
            // height: 100%;
            // max-height: 55px;
            display: block;
          }
        }
      }
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 6rem;

    .button {
      @include public_sans_text();
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0 18px;
      border-radius: 10px;
      height: 40px;
      text-transform: uppercase;
    }
  }
}