// .product-page {
  .product-system-guide {
    padding: 9rem 0;

    display: flex;
    flex-direction: row;
    gap: 4rem;

    
    @include media-breakpoint-down(xl) {
      padding: 3rem 0;
      flex-direction: column;
    }

    .overlay {
      display: none;
      background-color: rgba(0, 48, 108, 0.7);
      position: absolute;
      top: 0;
      left: 0;
      //border-radius: 20px;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    > * {
      flex: 1 1 50%;
    }

    .left-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 2rem;

      .body {

        img {
          width: 100%;
        }
      }
    }


    .right-col {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      justify-content: center;
      align-items: flex-start;

      .title,
      .title * {
        @include proxima_nova_text();
        font-weight: 700;
        font-size: 3.5rem;
        line-height: 3.6rem;
        color: $white;
        padding: 0;


        @include media-breakpoint-down(xl) {
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }

      ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        padding: 0;
        gap: 4rem;
        width: 100%;


        li {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          gap: 4rem;
          width: 100%;

          img {
            height: 65px;
            width: 65px;
            object-fit: cover;
          }

          .copy {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0.25rem;

            .heading {
              @include public_sans_text();
              font-size: 2.2rem;
              line-height: 3.08rem;
              font-weight: 500;
              color: $white;
            }

            .subheading {
              @include public_sans_text();
              font-size: 1.6rem;
              line-height: 2.3rem;
              font-weight: 500;
              color: $white;
            }
          }
        }
      }



      .button {
        @include public_sans_text();
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        line-height: 2.8rem;
        font-weight: 700;
        padding: 0 18px;
        border-radius: 10px;
        height: 40px;
        text-transform: uppercase;
        width: fit-content;
        margin-top: 2rem;
        margin-bottom: 2rem;

        @include media-breakpoint-down(xl) {
          margin: 0 auto;
        }
      }
    }

    &.dark_theme {
      background-color: $dark_blue;
    }

    &.light_theme {
      background-color: $white;

      .right-col {
        .title, 
        .title * {
          color: $dark_blue;
        }

        .copy {
          color: $medium_blue;

          .heading, .subheading {
            color: $medium_blue;
          }
        }
      }
    }
    
    &.image {
      position: relative;

      .overlay {
        display: block;
      }

      .left-col, .right-col {
        z-index: 9;
      }
    }
  
  }
// }