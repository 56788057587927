.thought-leadership {
  padding-top: 3rem;

  .top-section {
    display: flex;
    flex-wrap: wrap;

    .item {
      flex: 1 1 100%;
    }
    .item:nth-child(1) {
      order: 1;
      flex: 1 1 50%;
    }
    .item:nth-child(2) {
      order: 3;
      flex: 1 1 100%;
    }
    .item:nth-child(3) {
      order: 2;
      flex: 1 1 50%;
    }

    @include media-breakpoint-up(xl) {
      .item:nth-child(1) {
        order: 1;
        flex: 1 1 100%;
      }
      .item:nth-child(2) {
        order: 2;
        flex: 1 1 50%;
      }
      .item:nth-child(3) {
        order: 3;
        flex: 1 1 50%;
        // margin-left: auto;
      }
    }

    // go back
    .go-back {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      a {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        text-decoration: none;
        cursor: pointer;
        @include public_sans_text(1.4rem, 700, normal, 2.8rem);
        color: $light_blue;

        &:before {
          content: "";
          background-image: url("/dist/images/back-arrow-default-d.svg");
          display: inline-block;
          font-size: 2rem;
          margin-left: 1rem;
          width: 14px;
          height: 6px;
          transform: translateY(175%);
        }

        p {
          @include public_sans_text(1.4rem, 700, normal, 2.8rem);
          text-transform: uppercase;
          color: $light_blue;
        }

        &:hover {
          color: $medium_blue;

          &:before {
            background-image: url("/dist/images/back-arrow-hover-d.svg");
          }

          p {
            color: $medium_blue;
          }
        }
      }
    }

    // metadata
    .metadata {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      align-items: flex-end;

      @include media-breakpoint-down(xl) {
        justify-content: space-between;
      }

      .eyebrows {
        .button {
          @include public_sans_text(1.3rem, 500, normal, 1);
          padding: 0.8rem 1rem;
          border-radius: 0.5rem;
          &:hover {
            background: initial;
          }
        }
      }

      .publish-date {
        &,
        & p {
          @include public_sans_text(1.3rem, 500, normal, 2.8rem);
          color: $shadow_gray;
        }
      }
    }

    // social
    .social-share {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 2rem;
      align-items: flex-end;

      .share-text {
        @include public_sans_text(1.3rem, 700, normal, 2.8rem);
        color: $shadow_gray;
      }

      ul {
        list-style: none;
        display: flex;
        gap: 2rem;
        margin: 0;
      }
    }
  }
}
