.footer {
  background-color: $medium_blue;
  color: $white;

  .row > * {
    padding-left: 0;
    padding-right: 0;
  }

  * {
    color: $light_gray;
  }
  
  // logo + social + latest news (desktop)
  .logo-social {
    padding-top: 5rem;
    padding-bottom: 5rem;
    border-bottom: 1px solid rgba($medium_gray, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include media-breakpoint-down(xl) {
      justify-content: flex-start;
      border: none;
      padding-top: 5rem;
      padding-bottom: 0;
    }
    

    .left-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4rem;

      // logo
      .logo a > img {
        width: 160px;
        height: auto;
      }

      // social
      .social {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        p {
          @include public_sans_text();
          font-size: 2.0rem;
          font-weight: 400;
          line-height: 2.2rem;
        }

        > div {
          display: flex;
          flex-direction: row;
          gap: 2rem;
        }

        .links-wrapper {
            justify-content: space-between;
        }

        .image-link-block {
          .icon-link {
            // display: flex;
            // flex-direction: row;
            // gap: 1.55rem;
          }

          img {
            // max-width: 24px;
            width: 24px;
            height: auto;
          }
        }
      } 
    }

    .latest-news.desktop {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex-basis: 25%;
  
      @include media-breakpoint-down(xl) {
        justify-content: flex-start;
      }

      > div {
        display: flex;
        flex-direction: column;
      }

      .email-form-block {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 2rem;
        flex: 1 1 auto;
    
    
        .heading {
    
          p {
            @include public_sans_text();
            font-size: 2.0rem;
            font-weight: 400;
            line-height: 2.2rem;
          }
        }
      
        .subheading {
    
          p {
            @include public_sans_text();
            font-size: 1.2rem;
            line-height: 1.9rem;
            font-weight: 500;
          }
          
        }
    
    
        .email-form-container {
          height: 40px;
          background-color: $white;
          border-radius: 20px;
    
          form {
            height: inherit;
            border: none;
    
            align-items: center;
            display: flex;
            flex-direction: row;
    
            input.latest-news-email {
              color: $shadow_gray;
              @include public_sans_text();
              font-size: 1.2rem;
              border: none;
    
              height: 100%;
              padding-left: 2rem;
              border-radius: 20px;
    
              &:focus-visible {
                outline: none;
              }
            }
    
            button {
              border: none;
              background: none;
              padding-right: 2rem;
              padding-left: 2rem;
              height: 100%;
    
              align-items: center;
              display: flex;
              flex-direction: row;
            }
          }
    
          
    
        }
      }
    }
    
  }


  // menu - desktop + mobile
  .menu-wrapper {
    // padding-top: 3rem;
    // padding-bottom: 3rem;
    


    div {
      width: inherit;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
    }


    .menu-list {
      display: flex;
      flex-direction: column;
      gap: 2.75rem;


      // menu title
      p {
        @include public_sans_text();
        font-weight: 700;
        line-height: 2.2rem;
        font-size: 1.4rem;;
        text-transform: uppercase;
        color: $light_gray;
      }

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        li {
          font-size: 1.4rem;

          a {
            color: $light_gray;
            text-decoration: none;
            text-transform: capitalize;

            @include public_sans_text();
            font-weight: 400;
            font-size: 1.4rem;
            // line-height: 3.5rem;
            line-height: normal;

            @include media-breakpoint-down(xl) {
              // line-height: 3rem;
              line-height: normal;
            }

            &:hover {
              font-weight: 500;
            }
          }
        }
      }
    }
    
    
    &.desktop, .mobile {
      flex-direction: row;
      justify-content: space-between;

      padding-top: 5rem;
      padding-bottom: 9rem;
    }

    &.mobile {
      border-bottom: 1px solid rgba($medium_gray, 0.2);
      border-top: 1px solid rgba($medium_gray, 0.2);
      margin-bottom: 5rem;
      margin-top: 5rem;
      padding-top: 3rem;
      padding-bottom: 3rem;

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.75rem 0;
      }
    }


  }
  

  // latest news mobile
  .latest-news.mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @include media-breakpoint-down(xl) {
      justify-content: flex-start;
    }

    .email-form-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 2rem;
  
  
      .heading {
  
        p {
          @include public_sans_text();
          font-size: 2.0rem;
          font-weight: 400;
          line-height: 2.2rem;
        }
      }
    
      .subheading {
  
        p {
          @include public_sans_text();
          font-size: 1.2rem;
          line-height: 1.9rem;
          font-weight: 500;
        }
        
      }
  
  
      .email-form-container {
        height: 40px;
        background-color: $white;
        border-radius: 20px;
  
        form {
          height: inherit;
          border: none;
  
          align-items: center;
          display: flex;
          flex-direction: row;
  
          input.latest-news-email {
            color: $shadow_gray;
            @include public_sans_text();
            font-size: 1.2rem;
            border: none;
  
            height: 100%;
            padding-left: 2rem;
            border-radius: 20px;
  
            &:focus-visible {
              outline: none;
            }
          }
  
          button {
            border: none;
            background: none;
            padding-right: 2rem;
            padding-left: 2rem;
            height: 100%;
  
            align-items: center;
            display: flex;
            flex-direction: row;
          }
        }
  
        
  
      }
    }
  }






  // common
  

  


  // copyright
  .copyright-wrapper {
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include media-breakpoint-down(xl) {
      padding-top: 7rem;
    }


    display: flex;
    justify-content: space-between;

    .logo-wrapper {
      width: fit-content;
      padding-right: 2rem;

      a {
        display: block;
        width: 100%;

        img {
          display: block;
          max-width:100%;
          width: 150px;
          height: 25px;
        }
      }
    }

    .text-wrapper {
      width: fit-content;

      .copyright {
        @include public_sans_text();
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 2.2rem;
        color: $light_gray;
      }
    }

  }



}