.product-page {
  .product-certifications {
    background-color: $light_gray;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 4rem;

    .product-certification-item {
      flex: 0 0 auto;
    }

    img {
      max-height: 80px;
      width: auto;

      @include media-breakpoint-down(xl) {
        max-height: 50px;
      }
    }
  }
}