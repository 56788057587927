/** 
    * Buttons
    * -----------------------------------------------------------------------------
    * 
*/
.button {
  display: inline-flex;
  text-decoration: none; 
  border-radius: 1rem;
  font-size: 1.2rem;
  padding: 0.8rem 1.8rem;
  @include generate_color_schemes();

  &.directed {
    &::after {
      content: "→";
      width: -moz-fit-content;
      width: fit-content;
      font-size: 20px;
      margin-left: 9px;
      color: inherit;
    }
  }

  &:hover {
    background-color: $medium_blue;
    border-color: $medium_blue;
  }

  &.partner {
    padding: 0.6rem 2.1rem 0.6rem 0.9rem;

    img {
        width: 32px;
        height: 32px;
        margin-right: 1.5rem;

        @include media-breakpoint-down(xl) {
            width: 25px;
            height: 25px;
        }
    }
  }
}

.category-tag {
    display: inline-flex;
    text-decoration: none;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    background-color: transparent;
    padding: 0.375rem 1rem;
    text-transform: none;
    font-size: 1.3rem;
    @include generate_color_schemes_cat_tags();
    //remove this if category tags ever become links
    // per HENWR-1447
    &:not(a) {
      cursor: default;
      &:hover {
        background-color: unset !important;
      }
    }
}
