.quote {

  &.blade.full-width {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .quote-block {
      padding-left: 12rem;
      padding-right: 12rem;
    }
  }

  .quote-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // max-width: 1200px;
    border-radius: 20px;
    position: relative;
    margin: 0;

    &.light {
      background-color: $white;
  
      p {
        color: $medium_blue;
      }
  
      footer {
        color: $light_blue !important;
      }
    }
  
    &.dark,
    &.image {
      background-color: $medium_blue;
  
      p {
        color: $white;
      }
  
      footer {
        color: $light_aqua;
      }
    }
  
    &.image {
      background-size: cover;
    }
  
    &.no-footer {
      p {
        padding-bottom: 13rem;
    
        @include media-breakpoint-up(lg) {
          padding-bottom: 8rem;
        }
      }
    }
  
    .overlay {
      background-color: rgba(0, 48, 108, 0.7);
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 20px;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  
    .quote-wrapper-text {  
      padding: 5rem 2rem 2rem 2rem;
  
      @include media-breakpoint-up(lg) {
        padding: 6rem 6rem 6rem 6rem;
      }
      
      p {
        display: block;
        position: relative;
  
        @include praktika_text('Praktika ExtraBold');
    
        @include media-breakpoint-up(lg) {
          font-size: 4.5rem;
        }
    
        //This is -3%
        letter-spacing: -0.03rem;
        z-index: 9;
      }
    
      footer {
        color: $light_aqua;
        font-size: 1.6rem;
    
        @include media-breakpoint-up(lg) {
          font-size: 2rem;
        }
    
        padding-top: 5rem;
        padding-bottom: 5rem;
    
        @include media-breakpoint-up(lg) {
          padding-top: 8rem;
          padding-bottom: 0;
        }
  
        display: block;
        position: relative;
        z-index: 9;
      }
    }
  
    &.add-quote-marks {
      p {
        &:before,
        &:after {
          content: '';
          display: block;
          width: 40px;
          height: 25px;
          background-repeat: no-repeat;
          background-size: contain;
  
          @include media-breakpoint-up(lg) {
            width: 80px;
            height: 50px;
          }
        }
  
        &:before {
          background-image: url('/dist/images/left-quote-icon.svg');
  
          @include media-breakpoint-up(lg) {
            margin-left: 2rem;
          }
  
          margin-bottom: 2rem;
        }
    
        &:after {
          background-image: url('/dist/images/right-quote-icon.svg');
          position: absolute;
          right: -1rem;
  
          @include media-breakpoint-up(lg) {
            right: 2rem;
          }
  
          // margin-top: 3rem;
        }
      }
    }
  }
}