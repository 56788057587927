.link {
  display: inline-flex;
  text-decoration: none;

  &.directed {
    position: relative;
    padding-right: 20px;
    letter-spacing: 0.075rem;

    &::after {
      // content: "→";
      width: -moz-fit-content;
      width: fit-content;
      line-height: 2rem;
      font-size: 2rem;
      margin-left: .9rem;
      color: inherit;
      
      content: '';
      display: inline-block;
      width: 14px;
      height: 6px;
      background-image: url('/dist/images/forward-arrow-default-d.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        color: $medium_blue;
      }

      @include media-breakpoint-down(xl) {
        background-image: url('/dist/images/forward-arrow-default-m.svg');
      }

    }

    &:hover {
      color: $medium_blue !important;

      &::after {
        background-image: url('/dist/images/forward-arrow-hover-d.svg');
      
      }
    }
  }

  &:hover {
    color: $medium_blue;
  }
}
