.document-listing-page {
    display: flex;
    flex-direction: row;
    gap: 5rem;
    padding-top: 8rem;
    padding-bottom: 8rem;

    @include media-breakpoint-down(xl) {
        flex-direction: column;
        gap: 3.5rem;
    }

    .filters {
        flex: 0 0 25%;

        .filters-wrapper {
            display: flex;
            flex-direction: column;
            position: relative;


            .desktop-header {
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 2rem;
                border-bottom: 1px solid $medium_gray;

                @include media-breakpoint-down(xl) {
                    display: none;
                }


                .title {
                    display: flex;
                    flex: 0 0 50%;

                    @include public_sans_text();
                    font-size: 1.8rem;
                    line-height: 2.52rem;
                    font-weight: 700;
                    color: $dark_blue;

                    @include media-breakpoint-down(xl) {
                        display: none;
                    }
                }


                .reset {
                    @include public_sans_text();
                    font-size: 1.4rem;
                    line-height: 2.3rem;
                    font-weight: 500;
                    color: $light_blue;
                    margin-left: auto;
                }
            }


            .mobile-header {
                display: none;

                @include media-breakpoint-down(xl) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }

                .search-filters-button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;
                    gap: 1rem;
                    border-radius: 10px;
                    height: 40px;
                    text-transform: uppercase;
                    padding: 0 1.8rem;
                    background-color: $light_blue;
                    color: $white;
                    border: none;
                    position: relative;

                    img {
                        width: 12px;
                        height: 8px;
                    }

                    &, & span {
                        @include public_sans_text();
                        font-size: 1.4rem;
                        font-weight: 700;
                        text-transform: uppercase;
                        color: $white;
                    }
                }
            }

            .flyout-content {
                display: none;
                padding-bottom: 2rem;
                border-bottom: 1px solid $medium_gray;

                .title {
                    display: flex;
                    flex: 0 0 50%;

                    @include public_sans_text();
                    font-size: 1.8rem;
                    line-height: 2.52rem;
                    font-weight: 700;
                    color: $dark_blue;

                    @include media-breakpoint-down(xl) {
                        display: none;
                    }
                }

                .reset {
                    @include public_sans_text();
                    font-size: 1.4rem;
                    line-height: 2.3rem;
                    font-weight: 500;
                    color: $light_blue;
                    margin-left: auto;
                }

                .filters-close {
                    position: absolute;
                    right: 4rem;
                    top: 3rem;
                }
            }



            .filter-section {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding: 2rem 0;

                @include media-breakpoint-down(xl) {
                    display: none
                }

                &.noresults {
                    display: none !important;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $medium_gray;
                }
                // open state
                .filter-title {
                    @include public_sans_text();
                    font-size: 1.8rem;
                    line-height: 2.7rem;
                    font-weight: 700;
                    color: $dark_blue;
                    position: relative;
                    text-decoration: none;

                    &:after {
                        content: '';
                        background-image: url('/dist/images/vector.svg');
                        width: 12px;
                        height: 7px;
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                ul.filter-options {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    align-items: flex-start;


                    li {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 0.5rem;

                        input[type="radio"] {
                            margin-right: 10px;
                            appearance: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            width: 16px;
                            height: 16px;
                            border: 1px solid $medium_blue;
                            border-radius: 50%;
                            outline: none;
                            cursor: pointer;
                            position: relative;

                            &:checked {
                                background-color: $medium_blue;
                                border-color: $medium_blue;
                                position: relative;

                                &::before {
                                    content: '';
                                    display: block;
                                    width: 6px;
                                    height: 6px;
                                    margin: 3px;
                                    border-radius: 50%;
                                    background-color: white;
                                    position: absolute;
                                    top: 1px;
                                    left: 1px;


                                    @include media-breakpoint-down(xl) {
                                        margin: 2.5px;
                                    }
                                }
                                // :before
                            }
                            // :checked
                        }
                        // input radio


                        input[type="checkbox"] {
                            margin-right: 10px;
                            appearance: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            width: 16px;
                            height: 16px;
                            border: 1px solid $medium_blue;
                            border-radius: 0;
                            outline: none;
                            cursor: pointer;
                            position: relative;

                            &:checked {
                                background-image: url('/dist/images/radio-checked.svg');
                                background-size: contain;
                                position: relative;
                            }
                            // :checked
                        }
                        // input checkbox

                        label {
                            cursor: pointer;
                            @include public_sans_text();
                            font-size: 1.6rem;
                            line-height: 2.8rem;
                            font-weight: 400;
                            color: $shadow_gray;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                        }
                        // label
                    }
                    //li


                    &.button-style {
                        li {
                            display: flex;
                            align-items: center;
                            position: relative;

                            input[type="checkbox"] {
                                appearance: none;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                left: 0;
                                top: 0;
                                cursor: pointer;
                                z-index: 1;
                                opacity: 0;
                            }

                            label {
                                cursor: pointer;
                                font-size: 1.6rem;
                                line-height: 2.4rem;
                                font-weight: 400;
                                color: $shadow_gray;
                                padding: 0.5rem 1rem;
                                border: 1px solid $medium_gray;
                                border-radius: 10px;
                                width: 100%;
                                text-align: center;
                                background-color: $white;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                transition: background-color 0.3s, color 0.3s, border-color 0.3s;
                            }

                            input[type="checkbox"]:checked + label {
                                background-color: $light_blue;
                                color: $white;
                                border-color: $light_blue;
                                font-weight: 700;
                            }
                        }
                    }
                }
                //ul
                // close state
                &.close {
                    .filter-title {
                        &:after {
                            transform: rotate(270deg);
                        }
                    }

                    ul.filter-options, .search-bar {
                        display: none;
                    }
                }
            }
            //.filter-section


            .search-bar {
                display: flex;
                align-items: center;
                height: 48px;
                border-radius: 15px;
                border: 1px solid $dark_blue;

                form {
                    width: 100%;
                }

                .search-input-wrapper {
                    display: flex;
                    align-items: center;
                    position: relative;
                    gap: 1rem;

                    .search-input {
                        @include public_sans_text();
                        font-size: 1.6rem;
                        line-height: 2.8rem;
                        font-weight: 500;
                        color: $medium_blue;
                        border: none;
                        outline: none;
                        background: transparent;
                        width: 90%;
                        display: flex;
                        order: 1;

                        &::placeholder {
                            @include public_sans_text();
                            font-size: 3rem;
                            line-height: 2rem;
                            font-weight: 400;
                            color: $medium_blue;
                        }
                    }
                }

                .btn-submit {
                    width: 2.4rem;
                    height: 2.4rem;
                    background-size: 2.4rem;
                    background-image: url('/dist/images/search-icon-blue.svg');
                    background-repeat: no-repeat;
                    order: 0;
                    padding: 0;
                    margin-left: 1.4rem;
                    order: 0;

                    &:focus {
                        outline: 1px solid $medium_blue;
                    }
                }

                @include media-breakpoint-down(xl) {
                    height: 45px;

                    .search-input-wrapper {
                        .search-input {
                            font-size: 2rem;
                            line-height: 2rem;

                            &::placeholder {
                                font-size: 2rem;
                                line-height: 2rem;
                            }
                        }

                        .btn-submit {
                            width: 2rem;
                            height: 2rem;
                            background-size: 2rem;
                        }
                    }
                }
            }
            //search-bar
        }
        // mobile / flyout open state display settings
        &.mobile-expanded {
            position: fixed;
            height: 100%;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 4;

            .see-results {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .filters-wrapper {
                height: 100%;
                overflow-y: scroll;
                background-color: $white;
                padding: 8rem 4rem;

                .mobile-header {
                    display: none;
                }

                .flyout-content {
                    &, & * {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    }
                }

                .filter-section {
                    display: flex;
                }
            }
        }

        .see-results {
            @include public_sans_text();
            display: none;
            align-items: center;
            font-size: 1.4rem;
            font-weight: 700;
            padding: 0 18px;
            border-radius: 10px;
            height: 40px;
            text-transform: uppercase;
            text-decoration: none;
            background-color: $light_blue;
            color: $white;
            border: none;
            margin-top: 2rem;
        }
    }

    .search-container {
        flex: 0 0 75%;

        ul.results-list {
            list-style-type: none;
            padding: 0;
            margin: 0;

            .result-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 1.5rem;
                padding: 3rem 8.5rem 3rem 0;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    padding-bottom: 0;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $medium_gray;
                }

                @include media-breakpoint-down(xl) {
                    width: 100%;
                    padding: 2rem 8.3rem 2rem 0;
                }

                a {
                    text-decoration: none;
                }

                p {
                    @include public_sans_text();
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                    font-weight: 500;
                    color: $medium_blue;
                }

                a.download-wrapper {
                    display: flex;
                    flex-direction: row;
                    gap: 1.5rem;

                    @include media-breakpoint-down(xl) {
                        display: none;
                    }

                    .download {
                        @include public_sans_text();
                        font-size: 1.3rem;
                        line-height: 2.8rem;
                        font-weight: 700;
                        color: $orange;
                    }

                    .icon-wrapper {
                        height: 28px;
                        width: 24px;
                        display: flex;

                        img {
                            height: 100%;
                            width: auto;
                        }
                    }
                }
            }
        }

        .load-more {
            @include public_sans_text();
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.4rem;
            font-weight: 700;
            border-radius: 10px;
            height: 40px;
            text-transform: uppercase;
            text-decoration: none;
            background-color: $light_blue;
            color: $white;
            border: none;
            padding: 0 7rem;
            margin: 8.3rem auto 0;

            &.hidden {
                display: none;
            }

            @include media-breakpoint-up(xl) {
                &:hover {
                    background-color: $white;
                    color: $light_blue;
                    border: 2px solid $light_blue;
                }
            }

            @include media-breakpoint-down(xl) {
                padding: 0;
                margin-top: 3.5rem;
                width: 100%;
            }
        }

        .no-results {
            &.hidden {
                display: none;
            }

            h2 {
                @include praktika_text();
                font-size: 5.5rem;
                color: $dark_blue;
                margin-bottom: 2rem;
            }

            p {
                @include public_sans_text();
                font-size: 1.6rem;
            }
        }
    }
}
