.card.card-education {
  display: grid;
  grid-row: span 5;
  grid-template-rows: subgrid;
  gap: 0;
  padding: 2rem 2rem;
  padding-top: 0.4rem;
  background: $soft_gray;
  border: $soft_gray;
  border-radius: 1.5rem;
  .eyebrows {
    .category-tag:first-of-type {
        @extend .font_dark_blue;
        @extend .border_yellow;
    }

    .category-tag:nth-of-type(2) {
        @extend .font_shadow_gray;
        @extend .border_medium_gray;
    }

    margin-bottom: 2rem;
  }
  .heading {
    @include praktika_text("Public Sans", 2rem, 500, normal, 2.8rem);
    letter-spacing: 0.02em;
    margin-bottom: 2.4rem;

    @include media-breakpoint-down(xl) {
      font-size: 1.6rem;
      margin-bottom: 1.2rem;
    }
  }
  .copy {
    @include praktika_text("Public Sans", 1.6rem, 500, normal, 2.3rem);
    @include media-breakpoint-down(xl) {
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }
    letter-spacing: 0.02em;
    margin-bottom: 2rem;
  }
  .credits {
    @include public_sans_text(1.4rem, 500, normal, 2rem);
    @include media-breakpoint-down(xl) {
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }
    margin-bottom: 3rem;

    .label {
      font: inherit;
      font-weight: 700;
      text-transform: uppercase;
      @include public_sans_text(1.4rem, 700, normal, 2rem);

      @include media-breakpoint-down(xl) {
        font-size: 1.2rem;
      }
    }

    .credit_item {
      @include public_sans_text(1.4rem, 500, normal, 2rem);
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        font: inherit;
      }
    }
  }
  .ctas {
    text-align: center;

    @include media-breakpoint-down(xl) {
      text-align: left;
    }

    .cta {
      max-height: 5.25rem;
      @include public_sans_text(
        clamp(1.2rem, 1vw, 1.4rem),
        600,
        normal,
        1,
        uppercase
      );
      padding: 1.4rem 1.8rem;
      border-radius: 1rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
