.card.card-default.slide-wrapper {
  border-radius: 1.5rem;
  background: $soft_gray;
  border: none;

  &.outline {
    border: 1px solid $soft_gray;
  }
  
  &.card-video {
    .slide-image-wrapper {
      position: relative;

      .play-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;

        .play {
          
          display: flex;
          justify-content: center;
          align-items: center;
          width: 112px;
          height: 89px;
  
          @include media-breakpoint-down(xl) {
            width: 102px;
            height: 81.05px;
          }
        }
      }
      
    }
  }

  .slide-image-wrapper {
    img {
      width: 100%;
      border-top-left-radius: 1.5rem;
      border-top-right-radius: 1.5rem;
      max-height: 30rem;
      height: 100%;
      object-fit: cover;
      object-position: top;
      height: 320px;

      @include media-breakpoint-down(xl) {
        height: 250px;
      }
    }
  }

  .slide-text-wrapper {
    padding: 1rem 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .slide-category {
      font-size: 1.3rem;
      border: 2px solid $light_blue;
      border-radius: 5px;
      padding: 6px 12px;
      color: $shadow_gray;
      display: inline-block;
      vertical-align: middle;
    }

    .slide-name {
      font-size: 1.6rem;
      line-height: 2.3rem;
      color: $dark_blue;
      padding: 1.5rem 0 1.7rem;
    }

    .slide-link {
      display: block;
      padding-bottom: 1.2rem;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;      
      align-items: center;
      
      a {
        font-size: 1.4rem;
        line-height: 2.8rem;
        color: $light_blue;
        text-decoration: none;
        display: block;
        text-transform: uppercase;

        &.link.directed {
          &:after {
            right: unset;
          }
        }
      }

      p.publish-date {
        @include public_sans_text(1.3rem, 500, normal, 2.8rem);
        color: $shadow_gray;
      }
    }
  }
}

.system-products, .related-products {
  .slide-image-wrapper img {
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
}
