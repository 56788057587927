.four-card-block {
  padding-top: 7rem;
  padding-bottom: 7rem;

  background-color: $light_gray;
  @include media-breakpoint-down(xl) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .grid_heading {
    margin-bottom: 4.5rem;
    display: grid;
    grid-template-columns: auto 1fr;
    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
    }

    .large_text {
      @include proxima_nova_text(3.5rem, 700, normal);
      display: inline-block;
      margin-right: 2.8rem;
      @include media-breakpoint-down(lg) {
        margin-bottom: 4.5rem;
        font-size: 2.5rem;
      }
    }
    .separator {
      display: flex;
      flex-direction: row;
      .spacer_wrap {
        flex: 1;
        position: relative;
        .spacer {
          position: absolute;
          width: 100%;
          bottom: 0.8rem;
          border-bottom: 1px solid $medium_gray;
        }
        margin-right: 1.1rem;
      }
      .link {
        width: fit-content;
        @include public_sans_text(1.4rem, 500, normal, unset, uppercase);
        margin-bottom: 0.3rem;
        display: flex;
        align-items: flex-end;

        &:after {
          top: inherit;
          bottom: 1px;
        }
      }
    }
  }

  .splide__slide {
    width: 300px;
  }

  .splide__track {
    width: 120%;
  }
  .four-card-block_splide {
    display: flex;
    flex-direction: column;
  }

  @include media-breakpoint-up(xxl) {
    .grid_wrapper {
      display: grid;
      gap: 2.4rem;
      grid-template-columns: repeat(4, 1fr);
    }
    .four-card-block_splide {
      visibility: visible;
    }
    .splide__slide {
      width: 100%;
    }

    .splide__track {
      width: 100%;
    }
  }
  @include media-breakpoint-down(xxl) {
    .splide__track {
      order: 0;
    }
  }

  .splide__arrows {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    order: 1;

    .splide__arrow {
      background: unset;
      border: none;
      svg {
        display: none;
      }

      &:before {
        content: "";
        display: block;
        width: 50px;
        height: 38px;
        opacity: 1;
      }

      &.splide__arrow--prev {
        &[disabled], &[disabled="true"] {
          opacity: 0.25;
        }

        &.disabled {
          opacity: 0.25;
        }
        &:before {
          background-image: url("/dist/images/previous-arrow.svg");
        }
      }

      &.splide__arrow--next {
        &[disabled], &[disabled="true"] {
          opacity: 0.25;
        }
        
        &:before {
          background-image: url("/dist/images/next-arrow.svg");
        }
      }
    }
  }
}
