.four-card-split-grid-block {
  padding-top: 7rem;
  padding-bottom: 7rem;


  @include media-breakpoint-down(xl) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .grid_heading {
    display: flex;
    align-items: flex-end;
    margin-bottom: 4.5rem;

    @include media-breakpoint-down(xl) {
      flex-direction: column;
      align-items: flex-start;
      position: relative;

     // padding-right: 2rem; // last-card-fix
    }

    .large_text {
      @include proxima_nova_text(3.5rem, 700, normal, 1);
      display: inline-block;
      margin-right: 2.8rem;

      @include media-breakpoint-down(xl) {
        font-size: 2.5rem;
        margin-bottom: 2rem;
      }
    }


    .spacer_wrap {
      flex: 1;
      position: relative;


      @include media-breakpoint-down(xl) {
        width: calc(100% - 10rem);
        position: absolute;
        bottom: 0;
        left: 0;
      }


      .spacer {
        position: absolute;
        width: 100%;
        bottom: 0.6rem;
        border-bottom: 1px solid $medium_gray;
      }
      margin-right: 1.1rem;
    }
    .link {
      width: fit-content;
      @include public_sans_text(1.4rem, 500, normal, unset, uppercase);
      margin-bottom: 0.3rem;
      
      @include media-breakpoint-down(xl) {
        align-self: flex-end;
      }
    }
  }
 
  .splide__slide {
    width: 300px;
  }
  
  @include media-breakpoint-down(xxl) {
      .splide__track {
        width: 120%; // last-card-fix

        .splide__list {
            width: 80%;
        }
      }
  }

  .four-card-split-grid_splide {
    display: flex;
    flex-direction: column;
  }
  @include media-breakpoint-up(xxl) {
    .grid_wrapper {
      display: grid;
      gap: 2.8rem;
      grid-template-columns: repeat(2, 1fr);
    }
    .four-card-split-grid_splide {
      visibility: visible;
    }
    .splide__slide {
      width: 100%;
    }
    
    .splide__track {
      width: 100%;
    }
  }
  @include media-breakpoint-down(xxl) {
    .splide__track {
      order:0
    }
  }



  .splide__arrows {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    order: 1;

    @include media-breakpoint-down(xl) {
      padding-right: 2rem; // last-card-fix
    }

    .splide__arrow {
      background: unset;
      border: none;
      svg {
        display: none;
      }

      &:before {
        content: '';
        display: block;
        width: 50px;
        height: 38px;
        opacity: 1;
      }

      &.splide__arrow--prev {
        &[disabled], &[disabled="true"] {
          opacity: 0.25;
        }

        &.disabled {
          opacity: .25
        }
        &:before {
          background-image: url('/dist/images/previous-arrow.svg');
        }
      }

      &.splide__arrow--next {
        &[disabled], &[disabled="true"] {
          opacity: 0.25;
        }
        
        &:before {
          background-image: url('/dist/images/next-arrow.svg');
        }
      }
    }
  }



  &.paged {
    

    .no__splide__slide {
      width: 300px;
    }

    .no__splide__track {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .grid_wrapper {
      margin: 0 !important;
      padding: 0 !important;
    }

    @include media-breakpoint-up(xxl) {
      .no__splide__slide {
        width: 100%;
      }

      .no__splide__track {
        width: 100%;
      }

    }

    @include media-breakpoint-down(xxl) {
      .grid_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 6rem;
      }
    }
    
  }




  .pager-wrapper {
    padding-top: 3rem;

    ul.pager {
      list-style-type: none;
      display: flex;
      flex-direction: row;
      gap: 2rem;
      justify-content: center;

      li {

        a {
          text-decoration: none;
          @include public_sans_text(1.6rem, 700, normal, 2.8rem);
          color: $medium_blue;
          opacity: 0.25;
    
          &.active {
            opacity: 1;
          }
    
        }

      }
    }

    
    
  }
}