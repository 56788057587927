.full_width_hero {
  .wrapper {
    > video {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
        width: 100%;
        height: 100%;
        // height: 45.6rem; //64rem;
        position: absolute;
        object-fit: cover;
        z-index: 1;
        max-width: $max_content_width;
      }
    }

    .content-wrapper {
      position: absolute;
      z-index: 9;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      // height: 45.6rem; //70rem;

      &.video-True {
        @include media-breakpoint-up(lg) {
          background: none !important;
        }
      }

      &.has_bg_image {
        &.overlay {
          position: relative;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 48, 108, 0.7);
            z-index: 1;
          }
        }
      }
    }
  }

  .full_width_hero_content {
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .content {
      width: 100%;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      // top: 50%;
      // transform: translateY(-50%);
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
    .eyebrow {
      font-family: "Public Sans", sans-serif;
      font-weight: 500;
      font-style: normal;
      line-height: 1;
      text-transform: none;
      font-size: 1.6rem;
      margin-bottom: 2rem;
      color: $dark_blue;
      padding-top: 5rem;

      @include media-breakpoint-up(sm) {
        padding-top: 0;
      }

      &.dark {
        color: $light_aqua;
      }
    }

    .copy {
      margin-bottom: 2rem;

      h1, h2, h3, h4, h5, h6 {
        font-family: "Praktika ExtraBold";
        font-weight: 900;
        margin-bottom: 2rem;
        color: $dark_blue;
      }

      h1 {
        font-size: 4rem;
        line-height: 3.8rem;
      }

      h2 {
        font-size: 3.5rem;
        line-height: 3.6rem;
      }

      h3 {
        font-size: 3rem;
        line-height: 3.1rem;
      }

      h4 {
        font-size: 2.5rem;
        line-height: 2.6rem;
      }

      h5 {
        font-size: 2rem;
        line-height: 2.1rem;
      }

      h6 {
        font-size: 1.8rem;
        line-height: 1.9rem;
      }

      &.dark {
        h1, h2, h3, h4, h5, h6 {
          color: $white;
          font-family: "Praktika ExtraBold";
          font-weight: 900;
          margin-bottom: 2rem;
          
        }
        p {
          color: $light_aqua;
        }
      }

      h1 {
        @include praktika_text(
          "Praktika ExtraBold",
          clamp(4rem, 4vw, 5.5rem),
          900,
          normal,
          clamp(4.2rem, 4vw, 5.5rem)
        );
        letter-spacing: 0.02em;
        margin-bottom: 2rem;
        color: $dark_blue;
      }
      p {
        @include public_sans_text(
          clamp(1.6rem, 1.3vw, 2rem),
          500,
          normal,
          clamp(2.6rem, 1.9vw, 2.8rem)
        );
        color: $medium_blue;
      }
    }

    .ctas {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      // margin-bottom: 4.3rem;

      > div {
        max-height: 5.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .button {
        max-height: 5.25rem;
        @include public_sans_text(
          clamp(1.2rem, 1vw, 1.4rem),
          600,
          normal,
          1,
          uppercase
        );
        padding: 1.4rem 1.8rem;
        border-radius: 1rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &.partner {
          padding: 0.5rem 1.8rem;
          img {
            margin-right: 1.5rem;
          }
        }
      }
    }

    @include media-breakpoint-up(xxl) {
      flex-direction: row;
      gap: 3rem;
      .content {
        &.right_align {
          order: 1;
        }
      }
    }
  }

  .content,
  .full_width_hero_content {
    @include generate_color_schemes($createBorders: false);
  }
}