.card-list {
  padding: 6rem 6rem;
  overflow: hidden;

  //theme options
  &.white {
    background-color: $white;
  }

  &.gray {
    background-color: $light_gray;

    .card.card-default.slide-wrapper {
        background: $white;
        border: none;
    }
  }

  .category-tag {
    @extend .font_shadow_gray;

    &:first-of-type {
        @extend .border_light_blue;
    }

    &:nth-of-type(2) {
        @extend .border_medium_gray;
    }
  } 

  .scroll_list {
    .feed_heading {
      display: flex;
      align-items: flex-end;
      margin-bottom: 4.5rem;

      @include media-breakpoint-down(xl) {
        flex-direction: column;
        align-items: flex-start;
        position: relative;
      }

      .large_text {
        @include proxima_nova_text(3.5rem, 700, normal, 1);
        display: inline-block;
        margin-right: 2.8rem;

        @include media-breakpoint-down(xl) {
          font-size: 2.5rem;
          margin-bottom: 2rem;
        }
      }


      .spacer_wrap {
        flex: 1;
        position: relative;


        @include media-breakpoint-down(xl) {
          width: calc(100% - 10rem);
          position: absolute;
          bottom: 0;
          left: 0;
        }
        
        .spacer {
          position: absolute;
          width: 100%;
          bottom: 0.9rem;
          border-bottom: 1px solid $medium_gray;
        }
        margin-right: 1.1rem;
      }
      .link {
        width: fit-content;
        @include public_sans_text(1.4rem, 500, normal, unset, uppercase);
        margin-bottom: 0.3rem;

        @include media-breakpoint-down(xl) {
          align-self: flex-end;
        }
      }
    }

    .splide_feed {
      .splide__slide {
        article {
        }
      }
      .image {
        img {
          width: 100%; /* or any custom size */
          height: auto;
          aspect-ratio: 1;
          border-top-left-radius: 1.5rem;
          border-top-right-radius: 1.5rem;
        }
      }

      .content {
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        padding: 2rem 2.1rem;

        main .button {
          @include public_sans_text(1.3rem, 500, normal, 1.4rem);
          padding: 1rem;
          display: block;
          width: fit-content;
          margin-bottom: 1.7rem;
        }

        .body_text {
          @include public_sans_text(1.6rem, 500, normal, 2.3rem);
          margin-bottom: 2rem;
        }

        footer .link {
          @include public_sans_text(1.4rem, 500, normal, 2.1rem, uppercase);
        }
      }
    }
  }

  .card-list_splide {
    .splide__track {
      width: 120%;

      .splide__list {
        width: 80%;

        .splide__slide {
          width: 300px;
          border-radius: 15px;

          // card.scss
          .card.card-default {
            height: 100%;
          }
        }
      }
    }

    .splide__arrows {
      display: flex;
      justify-content: space-between;
      padding-top: 2rem;

      .splide__arrow {
        background: unset;
        border: none;

        &:before {
          content: "";
          display: block;
          width: 50px;
          height: 38px;
          opacity: 1;
        }

        &.splide__arrow--prev {
          &[disabled], &[disabled="true"] {
            opacity: 0.25;
          }

          &.disabled {
            opacity: 0.25;
          }
          &:before {
            background-image: url("/dist/images/previous-arrow.svg");
          }
        }

        &.splide__arrow--next {
          &[disabled], &[disabled="true"] {
            opacity: 0.25;
          }

          &:before {
            background-image: url("/dist/images/next-arrow.svg");
          }
        }
      }
    }
  }
}
