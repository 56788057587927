div.hubspot-form-wrapper.blade {
  padding: 6rem 0;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    @include media-breakpoint-up(xl) {
      &.two-col {
        flex-direction: row;
        gap: 12rem;
      }
    }

    // columns
    .item-wrapper {
      padding-left: 0;
      padding-right: 0;
      flex: 1;
      h2 {
        font-family: Praktika ExtraBold;
        font-size: 4rem;
        letter-spacing: -0.03rem;
        line-height: 1;
        color: $dark_blue;
        margin-bottom: 2.7rem;
        @include media-breakpoint-up(lg) {
          padding-right: 3rem;
          font-size: 5.5rem;
        }
      }

      p {
        @include public_sans_text();
        font-size: 1.6rem;
        line-height: 2.3rem;
        font-weight: 500;
        color: $shadow_gray;
        
        a {
          @include public_sans_text();
          font-size: 1.6rem;
          line-height: 2.3rem;
          font-weight: 500;
          text-decoration: none;
          color: $light_blue;
          padding: 0;

          &:hover {
            color: $medium_blue;
          }
        }
      }

      a {
        
        &.arrow-style {  
          text-transform: uppercase !important;
          font-weight: 700 !important;
          &:after {
            right: -20px;
          }
        }
      }
    }
  }
}

#hubspot-messages-iframe-container.widget-align-right.adjust-position {
  right: 4rem !important;

  @include media-breakpoint-up(xl) {
    right: 8rem !important;
  }

  &.menu-open {
    display: none !important;
  }

  @include media-breakpoint-down(lg) {
    &.open {
      right: 0 !important;
    }
  }
}