#header {
  max-width: 200rem;
  margin: 0 auto;
  background-color: $white;



  .header-desktop {
    display: none;

    @include media-breakpoint-up(xl) {
      display: block;
    }

    height: 109px;
  

    .utility-nav-wrapper {
      background-color: $dark_blue;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 5px 0;
      height: 30px;

      .utility-nav {
        max-width: $max_content_width;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        padding-left: 12rem;
        padding-right: 12rem;
    
        .utility-nav-item {
          padding: 5px 5px;
          display: flex;
          align-items: center;
    
          a {
            display: block;
            padding: 0 10px;
            color: $light_gray;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 1rem;
          }
    
          .utility-nav-child {
            display: none;
          }
        }
    
        .utility-nav-language {
          cursor: pointer;
    
          .utility-nav-language-selector {
            list-style-type: none;
            padding: 0;
            margin: 0;

            &-active {
              width: auto;
              position: relative;
              display: flex;

              &.multi-lingual:before {
                content:'';
                background-image: url('/dist/images/arrow-down.svg');
                display: block;
                position: relative;
                top: -2px;
                width: 10px;
                margin-right: 5px;

              }
            }
    
            img {
              vertical-align: unset;
            }
    
            .utility-nav-additional-languages {
              display: none;
              list-style-type: none;
              padding: 0;
              margin: 0;
    
              position: absolute;
              top: 20px;
              background-color: $dark_blue;
              width: 50%;
    
              .utility-nav-language-selector {
                display: flex;
                justify-content: center;
                padding: 5px 0;
    
                img {
                  vertical-align: unset;
                }
              }
    
              &.active {
                display: block;
                width: 40px;
                left: 0;
                z-index: 99;
              }
    
            }
          }
        }
      }
    }


    .main-nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // align-items: flex-start;
      padding-left: 12rem;
      padding-right: 12rem;
      
      height: 100px;

      @include media-breakpoint-up(xl) {
        max-width: $max_content_width;
        width: 100%;
        margin: 0 auto;
      }
      
      // logo
      .logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 200px;
        height: 130px;
        top: -30px;
        z-index: 100; //9;
        background-color: $medium_blue;
        border-radius: 0 0 10px 0;
        
        @include media-breakpoint-up(xl) {
          border-radius: 0 0 10px 10px;
          width: 230px;
          height: 146px;
        }

        @include media-breakpoint-up(xxl) {
          width: 250px;
        }
    
        
      }

      // meganav
      // l0
      ul.nav {
        .child-menu-wrapper {
          display: none;
        }

        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: inherit;
        padding: 0;
        margin: 0;
        list-style: none;
        height: 100px;

        justify-content: flex-start; // added for henwr-1156
        //henwr-1254: needs a bit more space for search on smaller screens
        //original 3rem gap is for xxl breakpoints now
        gap: 2rem; // added for henwr-1156
        flex-wrap: initial; // added for henwr-1156

        @include media-breakpoint-up(xxl) {
          gap: 3rem;
        }



        > li {
          // flex: 1; // commented out for henwr-1156
          text-align: center;
          // height: 100%; // commented out for henwr-1156

          &:not(:last-child) {
            .link-wrapper {
              position: relative;

              &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 25%;
                border-right: 1px solid $medium_gray;
                right: -3rem; // added for henwr-1156
              }
            }            
          }
          

          .link-wrapper {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
            width: 100%;
            padding-left: 3rem; // added for henwr-1156

            a {
              @include public_sans_text();
              font-size: 1.4rem;
              font-weight: 500;
              line-height: 2rem;
              color: $shadow_gray;
              padding: 0;
              width: auto;
              cursor: default;
              height: calc(100% - 10px);
              vertical-align: middle;
              display: flex;
              align-items: center;
  
            }
          }

          
        
          
          &.active {
            .link-wrapper.active {
              a {
                //border-bottom: 10px solid $dark_blue;
                //border-radius: 5px;
                //margin-top: 10px;
                position: relative;

                &:after {
                  content: "";
                  width: 100%;
                  background-color: $dark_blue;
                  height: 10px;
                  position: absolute;
                  top: calc(100% - 5px);
                  border-top-left-radius: 5px;
                  border-top-right-radius: 5px;
                  //left: 15px;
                }
              }

              &:not(.has-child-menu) {
                cursor: pointer;
                a {
                  cursor: pointer;
                }
              }
            }

            // l1
            > .child-menu-wrapper {
              background-color: $white;
              display: flex;
              position: absolute;
              min-height: 45rem;
              max-width: 200rem;
              left: 0;
              right: 0;
              width: 100%;
              margin: 0 auto;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              z-index: 99;
              box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.3);
              height: 100%;

              .inner-wrapper {
                max-width: $max_content_width;
                width: 100%;
                margin: 0 auto;
                padding-top: 4rem;
                padding-bottom: 4rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                padding-left: 12rem;
                padding-right: 12rem;

                .menu-blocks {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  position: absolute;
                  width: 33.33%;
                  left: 66.66%;
                  top: 4rem;
                  height: 100%;
                  gap: 0.5rem;
                  // z-index: 3;

                  @include media-breakpoint-down(xl) {
                    display: none;
                  }

                  .featured-block {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    .image {
                      flex: 1;
                      img {
                        width: auto;;
                        height: 11.8rem;
                        max-height: 11.8rem;
                        aspect-ratio: 1;
                        -o-object-fit: cover;
                        object-fit: cover;
                      }
                    }

                    .details {
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      align-items: flex-start;
                      flex: 3; //1;

                      background-color: $light_gray;
                      height: 100%;
                      max-height: 11.8rem;

                      padding-top: .2rem;
                      padding-bottom: .2rem;
                      padding-left: 1rem;
                      padding-right: 1rem;

                      .type {
                        @include public_sans_text();
                        font-size: 1.2rem;
                        font-weight: 500;
                        line-height: 2.8rem;
                        color: $shadow_gray;
                        text-align: left;
                      }

                      .title {
                        @include public_sans_text();
                        font-size: 1.6rem;
                        font-weight: 500;
                        line-height: 2.2rem;
                        color: $medium_blue;
                        text-align: left;

                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                      }

                      .link {
                        @include public_sans_text();
                        font-size: 1.2rem;
                        font-weight: 700;
                        line-height: 2.8rem;
                        color: $light_blue;
                        text-align: left;
                        cursor: pointer;

                        &:hover {
                          color: $dark_blue;
                        }
                      }
                    }
                  }
                }

                ul.child-menu {
                  margin: 0;
                  width: 33.33%;
                  list-style: none;
                  height: auto;
                  border-radius: 0;
                  border: none;
  
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  
                  margin-top: 0;
                  margin-bottom: 0;
                  padding-left: 0;
                  padding-right: 0;
                          
                  
                
                  > li {
                    width: 92.5%;
  
                    .link-wrapper {
                      height: auto;
                      justify-content: flex-start;

                      border-bottom: 1px solid $medium_gray;
                      border-radius: 0;
                      padding-top: 1.5rem;
                      padding-bottom: 1.5rem;
                      width: 92.5%;
  
                      &::before {
                        content: none;
                        border: none !important;
                      }
                      
  
                      a {
                        @include proxima_nova_text();
                        font-weight: 500;
                        font-size: 1.8rem;
                        line-height: 1.8rem;
                        color: $medium_blue;
                        text-align: left;
                        border: none;
                        max-width: 85%;
                        //padding-left: 2rem;
                      }

                      &.remove-border {
                        border-bottom-color: transparent;
                      }
                    }
  
                    &.active {  
                      .link-wrapper.active {
                        background-color: $dark_blue;
                        border-color: transparent !important;
                        border-radius: 5px;
                        position: relative;
  
                        a {
                          color: $white;
                          border: none !important;
                          margin-top: 0;
                          width: 100%;
                        }

                        &.has-child-menu {
                          a {
                            &::after {
                              content: '';
                              background-image: url("/dist/images/menu-link-vector.svg");
                              position: absolute;
                              top: 50%;
                              transform: translateY(-50%);
                              right: -20px;
                              width: 8px;
                              height: 14px; 
                              border-radius: 0;
                            }
                          }
                        }

                        &:not(.has-child-menu) {
                          cursor: pointer;
                          a {
                            cursor: pointer;
                            &:after {
                              content: none;
                            }
                          }
                        }
                      }
                      
  
                      // l2
                      > .child-menu-wrapper {
                        display: flex;
                        position: absolute;
                        left: 33.33%;
                        top: 4rem;
                        width: 33.33%;
                        // z-index: 4;
                        // background-color: $white;
  
                        .inner-wrapper {
                          position: absolute;
                          padding: 0;

                          ul.child-menu {
                            width: inherit;
    
                            > li {
                              width: 92.5%;
    
                              .link-wrapper {
                                height: auto;
                                
                                &::before {
                                  content: none;
                                  border: none !important;
                                }
                                
                                a {
                                  @include proxima_nova_text();
                                  font-weight: 500;
                                  font-size: 1.8rem;
                                  line-height: 1.8rem;
                                  color: $medium_blue;
                                  text-align: left;
                                  max-width: 85%;
                                  padding-left: 2rem;
                                }
                              }
    
                              &.active {
                                .link-wrapper.active {
                                  background-color: $dark_blue;
                                  border-color: transparent !important;
                                  
                                  a {
                                    color: $white;
                                    border: none !important;
                                    margin-top: 0;
                                  }
                                }
                                
                              
                              
                                // l3
                                > .child-menu-wrapper {
                                  display: flex;
                                  position: absolute;
                                  left: 91.5%;
                                  width: 100%;
                                  top: 0;

                                  // > .inner-wrapper > ul > li > .link-wrapper {
                                  //   width: 100%;
                                  // }
                                  

                                  // below this inner-wrapper *
                                  // inherit from l2
    
                                  // .inner-wrapper {
                                  //   position: absolute;
                                  //   padding: 0;

                                  //   ul.child-menu {
                                  //     width: inherit;
      
                                      
                                      
                                  //     > li {
                                  //       width: 100%;
      
                                  //       .link-wrapper {
                                  //         height: auto;
                                          
                                  //         &::before {
                                  //           content: none;
                                  //           border: none !important;
                                  //         }
      
                                  //         a {
                                  //           @include proxima_nova_text();
                                  //           font-weight: 500;
                                  //           font-size: 1.8rem;
                                  //           line-height: 1.8rem;
                                  //           color: $medium_blue;
                                  //           text-align: left;
                                  //         }
                                  //       }
                                        
      
                                  //       &.active {
                                  //         background-color: $medium_blue;
      
                                  //         .link-wrapper.active {
                                  //           border: none !important;
      
                                  //           a {
                                  //             color: $white;
                                  //             border: none !important;
                                  //             margin-top: 0;
                                  //           }
                                  //         }
                                          
                                  //       } // l3 active
                                  //     } // l3 li
                                  //   } // l3 ul
                                  // }
    
                                  
                                } // l3 end
                                
    
    
                              } // l2 active end
                            } // l2 li
                          }// l2 ul
                        }
  
                        
                      }// l2 end
  
  
                    } // l1 active
                  } // l1 li
                } // l1 ul
              }

              
            } // l1 end
            
          } // l0 active
        } // l0 li end
      } // l0 ul


      .search-wrapper {
        height: inherit;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 1.5rem;
      }
      
    } // main-nav



    // search
    .search-wrapper {
      height: inherit;

      form {
        position: relative;
        z-index: 9;
      }
  
      .search-input-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        border-radius: 25px;
        background: $search_background_blue;
        
        @include media-breakpoint-up(xxl) {
          width: 225px;
        }
  
        .search-input {
          display: block;
          background: transparent;
          border: 1px solid transparent;
          color: $medium_blue;
          font-size: 1.6rem;
          width: 190px;
          height: 40px;
          padding: 0 20px;
          overflow-y: scroll;
          position: relative;
          z-index: 9;

          &::placeholder {
            color: $medium_blue;
          }

          &:focus, &:focus-visible {
            border: none;
            outline: none;
          }
        }
      }
  
      .btn-submit {
        background-image: url('/dist/images/search-icon-blue.svg');
        background-repeat: no-repeat;
        background-size: 1.8rem;
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 2rem;
        z-index: 9;

        &:focus {
          outline: 1px solid $medium_blue;
        }
      }
    }
  } // header-desktop









  .header-mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 130px;
    


    .menu-blocks {
      display: none;
    }


    .blue-bar {
      display: block;
      background-color: #00306c;
      height: 30px;
      width: 100%;

      &::before {
        content: '';
        height: 30px;
        width: 100%;
        background-color: $dark_blue;
        display: block;
      }
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }

    .nav-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      

      .logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 200px;
        height: 130px;
        top: -30px;
        z-index: 100; //9;
        background-color: $medium_blue;
        border-radius: 0 0 10px 10px;
    
        @include media-breakpoint-up(xl) {
          width: 200px;
          height: 146px;
        }

        @include media-breakpoint-up(xxl) {
          width: 250px;
        }
      }


      .menu-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 1.5rem;
        margin-right: 1.5rem;


        #hamburger, 
        #mobile-search {
          display: flex;
          background: none;
          border: none;
          position: relative;
          display: inline-block;
          padding: 0 10px;
        }
      }
    } // nav-wrapper 

    .search-expanded-wrapper {
      display: none;

      position: absolute;
      width: 100%;
      left: 0;
      top: 11rem;
      right: 0;
      z-index: 10;
      background-color: $white;
      box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.3);


      &.active {
        display: flex;
      }

      & .search-wrapper {
        padding: 3rem 2rem 2rem;
        width: 100%;
      }
    }

    .nav-expanded-wrapper {
      background-color: $white;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 2rem;

      position: fixed;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 10;

      &:not(.active) {
        display: none;
      }

      .inner-expanded-wrapper {
        height: 100%;
        width: 100%;

        overflow-y: scroll;
        background-color: $white;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        align-items: flex-start;
        justify-content: flex-start;

        padding: 3rem 0;

        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-y: scroll;
        -ms-overflow-style: none;

        // position: absolute;
        // left: 0;
        // right: 0;
        // width: auto;

        
        &::-webkit-scrollbar {
          display: none;
        }
      }

      .top-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-bottom: 1px solid $medium_gray;

        // logo

        // close
        #close {
          display: flex;
          background: none;
          border: none;
          position: relative;
          margin-top: 1rem;
          padding: 0;
        }
      }

      .search-wrapper {
        width: 100%;

        padding: 0 2rem;
      }

      

      .nav-tree {
        width: 100%;
        
        // l0
        ul.nav {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          list-style-type: none;
          margin: 0;
          padding: 0;
          // gap: 1.5rem;

          .child-menu-wrapper:not(.active) {
            display: none;
          }

          li {
            width: 100%;
            
            a {
              @include public_sans_text();
              font-size: 2.2rem;
              font-weight: 500;
              line-height: 2rem;
              color: $shadow_gray;
              margin: 0;
              padding: 0;
              border-bottom: 1px solid $medium_gray;
              padding: 2rem 2rem;
            }


            // l1
            .child-menu-wrapper {
              .inner-wrapper {
                ul.child-menu {
                  list-style-type: none;
                  margin: 0;
                  padding: 0;

                  li {

                    a {
                      @include proxima_nova_text();
                      font-weight: 600;
                      font-size: 1.8rem;
                      line-height: 2.5rem;
                      color: $medium_blue;
                      // padding: 0;
                      padding: 1rem 2rem;
                    }

                    &.active {                    
                      a {
                        background-color: $dark_blue;
                        color: $white;
                      }
                    }


                    // l2
                    .child-menu-wrapper {
                      .inner-wrapper {
                        ul.child-menu {
                                  
                          li {

                            a {
                              background-color: $light_gray;
                              color: $medium_blue;
                              padding: 1rem 3rem;
                            }


                            &.active {
                              a {
                                background-color: $light_gray;
                                color: $shadow_gray;
                              }
                            }


                            // l3
                            .child-menu-wrapper {
                              .inner-wrapper {
                                ul.child-menu {
                                  
                
                                  li {
                                    a {
                                      background-color: $shadow_gray;
                                      color: $white;
                                    }
                                  }
                                } // end ul l3
                              }
                            } // end l3 child-menu-wrapper
                          
                          
                          } // end li / l2 
                        } // end ul l2
                      }
                    } // end l2 child-menu-wrapper
                  
                  
                  } // end li l1
                } // end ul l1
              }
            } // end l1 child-menu-wrapper        
          
          
          } // end li l0
        } // end ul l0
      
      } // nav-tree



      // mobile utility nav
      .utility-nav {
        width: 100%;
        padding: 2rem 2rem;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;
    
        .utility-nav-item {
          display: flex;
          align-items: center;
    
          a {
            @include public_sans_text();
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 2rem;
            color: $dark_blue;
            text-decoration: none;
            text-transform: uppercase;
          }
    
          .utility-nav-child {
            display: none;
          }
        }
        

        // selector "dropdown"
        .utility-nav-language {
          cursor: pointer;
          width: 100px;
    
          ul.utility-nav-language-selector {
            list-style-type: none;
            padding: 0;
            margin: 0;
            position: relative;

            li.utility-nav-language-selector-active {
              width: auto;
              position: relative;
              display: flex;

              &.multi-lingual::before {
                content:'';
                background-image: url('/dist/images/chevron-down.svg');
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -100%; //right: 0;
                width: 12px;
                height: 8px;
              }
            }
    
            img {
              vertical-align: unset;
            }
            

            // 
            ul.utility-nav-additional-languages {
              display: none;
              list-style-type: none;
              padding: 0;
              margin: 0;
    
              position: absolute;
              top: 20px;
              width: 50%;
    
              li.utility-nav-language-selector {
                display: flex;
              }
    
              &.active {
                display: flex;
                flex-direction: column;
              }
            }
          }
        }
      }


      
    }// nav-expanded-wrapper
  
  
  
  
    // common mobile states
    .search-input-wrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-radius: 25px;
      background: $search_background_blue;
      
      .search-input {
        display: block;
        background: transparent;
        border-radius: 25px;
        border: 1px solid transparent;
        color: $medium_blue;
        font-size: 1.6rem;
        min-width: 225px;
        height: 40px;
        padding: 0 40px 0 20px;
        overflow-y: scroll;
        width: 100%;
        outline: none;
        z-index: 9;

        &::placeholder {
          color: $medium_blue;
        }
      }

      .btn-submit {
        background-image: url('/dist/images/search-icon-blue.svg');
        background-repeat: no-repeat;
        background-size: 1.8rem;
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 2rem;
        z-index: 9;

        &:focus {
            outline: 1px solid $medium_blue;
        }
      }
    }
  
  
  } // header-mobile

}