.social-share {
  position: relative;

  #link-share-modal {
    display: none;
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 999;
    width: 250px;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background-color: #ffffff;
    
    @include media-breakpoint-down(md) {
      left: 0;
      right: unset;
    }

    &.active {
      display: block;
    }

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      margin-bottom: 1rem;
      font-size: 1.5rem;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .link-share-modal-content {
      padding: 10px 10px;

      input {
        font-size: 1.5rem;
        display: block;
        width: 100%;
        margin-top: 30px;
      }
  
      #share-link-copy {
        display: block;
        position: relative;
        color: $black;
        font-size: 1.5rem;
        text-align: center;
        background: none;
        border: none;
        text-decoration: underline;
        margin: 1rem auto;

        &:hover {
          text-decoration: none;
        }
      }
  
      .copied-message {
        display: block;
        margin-top: 1rem;
        font-size: 1.5rem;
        text-align: center;
      }
    }

  }
}