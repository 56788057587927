.henry-modal {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;

  .modal {

    &.fade.show {
      display: block;
      position: relative;
      padding-right: 0 !important;

      @include media-breakpoint-down(xl) {
        display: flex !important;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
      }
      
    }

    .modal-dialog {
      max-width: 72%;

      @include media-breakpoint-down(xl) {
        max-width: 90%;
      }

      .modal-content {
        padding: 0;
        max-height: calc(100vh - 3.5rem);
        overflow-y: auto;

        .modal-header {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          button {
            border: none;
            outline: none;
            background-color: transparent;
          }
        }


        .modal-body {

          #videoPlayer {
            width: 100%;
            height: auto;
          }

          iframe.video {
            height: 500px;
            width: 100%;
          }

          .hubspot-form-wrapper {
            padding-left: 5rem;
            padding-right: 5rem;

            @include media-breakpoint-down(xl) {
                padding-left: 2rem;
                padding-right: 2rem;
            }
          }
        }
      }// content
    } // dialog
  } // modal


}