.card.card-wide {
  background: transparent;
  border: none;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  // image
  .card-image {
    position: relative;

    img {
      border-radius: 10px;
      width: 100%;
      height: auto;
      aspect-ratio: 1.44;
      object-fit: cover;
    }

    .play-wrapper {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        width: 100%;
        height: 100%;

        .play-button {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            width: 112px;
            height: 89px;
        }
    }
  }

  // content
  

  //eyebrows
  .eyebrows {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1.5rem;

    &.show-date {
      margin-bottom: -0.5rem;
    }

    .category-tag {
      vertical-align: middle;

      &:first-of-type {
        @extend .font_dark_blue;
        @extend .border_yellow;
      }

      &:nth-of-type(2) {
        @extend .font_shadow_gray;
        @extend .border_medium_gray;
      }
    }
  }

  .card-body {
    &.show-date {
      &:not(.show-eyebrows) {
        margin-top: -2rem;
      }
    }
  }

  .date {
    @include public_sans_text(1.3rem, 500, normal, 2.4rem);
    display: inline-block;
    margin-bottom:1rem;
  }

  // title
  .card-title {
    @include public_sans_text(1.8rem, 500, normal, 2.4rem);
    color: $light_aqua;
    margin-bottom:1rem;
    margin-top: 0;
  }

  // description
  .card-text {
    @include public_sans_text(1.4rem, 500, normal, 2rem);
    color: $white;
  }

  // link
  .card-link {
    @include public_sans_text(1.4rem, 700, normal, 2.8rem, 1);
    color: $light_blue;
    text-decoration: none;
    text-transform: uppercase;
  }

  &.light {
    .card-title {
      color: $medium_blue;
    }

    .card-text {
      color: $shadow_gray;
    }

    .eyebrows {
      .button {
        color: $dark_blue;
      }
    }
  }

  &.dark {
    .eyebrows {
      .button {
        color: $white;
      }
    }
  }
}
