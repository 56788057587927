.top-content-section {
    @extend .copy-block;

    .content-wrapper .body {
        img {
            border-radius: 15px;

            @include media-breakpoint-up(lg) {
                width: 50%;
            }

            @include media-breakpoint-down(lg) {
                margin-bottom: 3rem;
                text-align: center;
                max-width: 100%;
            }
        }

        &.left_align img {
            @include media-breakpoint-up(lg) {
                float: right;
                margin: 0 0 4rem 4rem;
            }
        }

        &.right_align img {
            @include media-breakpoint-up(lg) {
                float: left;
                margin: 0 4rem 4rem 0;
            }
        }
    }
}
