.video-section-block {
  margin: 5rem 0;

  .video-section {
    display: flex;
    flex-direction: column;
    justify-content: center;  
    position: relative;
    margin: 0;
    padding: 4rem 2rem;

    @include media-breakpoint-up(lg) {
      padding: 7rem 8rem;
    }
    
    > video {
      display: none;

      @include media-breakpoint-up(lg)  {
        display: block;
        height: 100%;
        position: absolute;
        object-fit: cover;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .video-section-text {
      display: block;
      position: relative;
      text-align: center;
      padding-bottom: 4rem;
  
      p, span, h1, h2, h3, h4, h5, h6 {
        @include praktika_text('Praktika ExtraBold');
        color: $white;
        //font-size: 4.0rem;
        padding: 1rem 0;

        @include media-breakpoint-up(lg) {
          //font-size: 5.5rem;
        }

        &.eyebrow {
          @include public_sans_text();
          font-size: 1.6rem;
          color: $light_aqua;

          &.empty {
            padding: 0;

            &+ * {
               padding-top: 0; 
            }
          }

          @include media-breakpoint-up(lg) {
            font-size: 2.0rem;
          }
        }

        &:first-child {
            padding-top: 0;
        }
      }

      h1 {
        font-size: 4rem;
        line-height: 3.8rem;

        @include media-breakpoint-up(md) {
          font-size: 5.5rem;
        }
      }

      h2 {
        // @include proxima_nova_text();
        font-weight: 700;
        font-size: 3.5rem !important;
        line-height: 3.6rem;
        // color: $medium_blue;
        // padding-right: 3rem;
        margin-bottom: 0 !important;
      }

      h3 {
        font-size: 3.0rem;
        line-height: 3.1rem;
      }

      h4 {
        font-size: 2.5rem;
        line-height: 2.6rem;
      }

      h5 {
        font-size: 2.0rem;
        line-height: 2.1rem;
      }

      h6 {
        font-size: 1.8rem;
        line-height: 1.9rem;
      }

      span {
        font-size: unset;
      }

      p, strong, em {
        font-size: 1.6rem;
      }
    }

    .video-section-video {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;

      @include media-breakpoint-up(lg) {
        width: 50%;
        height: 50%;
      }
      
      position: relative;
      margin: 0 auto;
      cursor: pointer;

      img.play-button {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        width: 100px;
        height: 85px;

        @include media-breakpoint-up(lg) {
          width: auto;
          height: auto;
        }
      }
      video, .video {
        display: block;
        width: 100%;
        height: 100%;
        min-height: 187px;
        object-fit: cover;
        z-index: 0;
        pointer-events: none;

        @include media-breakpoint-up(lg) {
          min-height: 440px;
        }

        &.clicked {
            pointer-events: auto;
        }
      }
    }
  }

  &.video {
    .video-section {
      &.bg-image {
        background-size: cover;
      }
      &.bg-video {
        background-size: cover;
        
        @include media-breakpoint-up(lg) {
          background-size: 0;
        }
      }
    }
  }
  
  &.light {
    background-color: $white;

    p, span, h1, h2, h3, h4, h5, h6  {
      color: $medium_blue !important;
    }
  }

  &.dark,
  &.image {
    background-color: $medium_blue;

    p, span, h1, h2, h3, h4, h5, h6  {
      color: $white;
    }
  }
}