.product-categories-block {
  padding: 7rem 0;
  background-color: $light_gray;

  @include media-breakpoint-down(xl) {
    padding: 3rem 0;
  }

  &.dark_theme {
    background-color: $medium_blue;
  }

  .container-fluid {
    padding: 0;
  }
  
  .block-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;
  }



  // heading
  .heading-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;

    * {
      @include proxima_nova_text(3.5rem);
      color: $medium_blue;
      font-weight: 700;
      line-height: 3.6rem;

      @include media-breakpoint-down(xl) {
        font-size: 2.5rem;
      }
    }

    &.dark_theme {
      * {
        color: $white;
      }
    }

  }


  // image links
  .items-wrapper {
    padding-left: 0;
    padding-right: 0;


    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 2.6rem; //4.2rem;
    align-items: center;
    justify-items: center;

    @include media-breakpoint-down(xl) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr;
      align-items: center;
      justify-items: center;
      row-gap: 1.2rem;
      column-gap: 0.75rem;
    }

    > div {
      padding-left: 0;
      padding-right: 0;
      width: auto;
      display: none;

      @include media-breakpoint-up(xl) {
        &:nth-child(-n+12) {
          display: block;
        }
      }
      
      @include media-breakpoint-down(xl) {
        &:nth-child(-n+9) {
          display: block;
        }
      }
    }

    .image-link-block {
      padding-left: 0;
      padding-right: 0;
      width: auto;
      display: none;

      @include media-breakpoint-up(xl) {
        &:nth-child(-n+12) {
          display: block;
        }
      }
      
      @include media-breakpoint-down(xl) {
        &:nth-child(-n+9) {
          display: block;
        }
      }

      
      .icon-link {

        a {
          position: relative;

          &:hover .image-container {
            transform: scale(1.1);
          }
        }

        .image-container {
          position: relative;
          display: inline-block;
          
          // &:hover .item-image {
          //   transform: scale(1.2);
          // }

          transition: transform 0.5s;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            // opacity: 0;
            border-radius: 15px;
            transition: opacity 0.3s ease;
          }
        
          &:hover::before {
            opacity: 0.5;
          }

          .item-image {
            width: 100%;
            height: auto;
            display: block;
            border-radius: 15px;
            object-fit: cover;
            width: 166px;
            height: 166px;

            @include media-breakpoint-down(xl) {
              width: 100px;
              height: 100px;
            }

            @media (max-width: 340px) {
              width: 80px;
              height: 80px;
            }
  
            // transition: transform 0.5s;
          }

          p {
            @include public_sans_text();
            font-size: 1.4rem;
            font-weight: 700;
            line-height: 1.6rem;
            color: $white;
            position: absolute;
            bottom: 0.75rem;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            text-align: center;
            color: white;
            margin: 0;
            padding: 0 1rem;
  
            @include media-breakpoint-down(xl) {
              font-size: 1.2rem;
            }
          }
        }

        
        
      }
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
  
    .button {
      @include public_sans_text();
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 700;
      padding: 0 18px;
      border-radius: 10px;
      height: 40px;
      text-transform: uppercase;

      background-color: $light_blue;
      color: $white;
      border: none;

      &:hover {
        background-color: $white;
        color: $light_blue;
        border: 2px solid $light_blue;
      }
    }


    &.dark_theme {
      .button {
        background-color: $white;
        color: $medium_blue;
        border: none;

        &:hover {
          background-color: $light_blue;
          color: $white;
          border: 2px solid $light_blue;
        }
      }
    }


  }

}



// // theme stuff
// .product-categories-block {  
//   &, &.light_theme {
//     background-color: $light_gray;

//     p {
//       color: $white;
//     }

//     #pc-load-more {
//       background-color: $light_blue;
//       color: $white;
//       border: none;
//     }
//   }

//   &.dark_theme {
//     background-color: $medium_blue;

//     p {
//       color: $white;
//     }

//     #pc-load-more {
//       background-color: $white;
//       color: $medium_blue;
//       border: none;
//     }
//   }
// }