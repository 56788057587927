// .product_page {
  .product-hero {
    padding-top: 3rem;

    
    .featured_content_item {
      height: 100%;
      > div {
        height: 100%;
      }
      > video {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
          border-radius: 20px;
          z-index: 1;
        }
      }
      
    

      .product {
        height: 100%;
        padding: 3rem 0;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-evenly;

        @include media-breakpoint-up(xl) {
          flex-direction: row;
          gap: 3rem;
          padding-bottom: 10rem;
          .content {
            &.right_align {
              order: 1;
            }
          }
        }

        .copy {

          &:not(&.top) {
            margin-bottom: 2rem;
          }
          &.dark {
            h1,
            p {
              color: $white;
            }
          }

          &.top {
            h1 {
              color: $dark_blue !important;
            }
          }

          h1 {
            @include praktika_text('Praktika ExtraBold');
            font-size: 5.5rem;
            line-height: 5.5rem;
            margin-bottom: 0;
            color: $dark_blue;


            @include media-breakpoint-down(xl) {
              font-size: 4.0rem;
              line-height: 4.2rem;
            }
          }
          p {
            @include public_sans_text();
            font-size: 1.6rem;
            line-height: 2.3rem;
            font-weight: 500;
            margin-bottom: 2rem;
            //color: $dark_blue;
            color: $medium_blue;


            @include media-breakpoint-down(xl) {
              font-size: 1.4rem;
              line-height: 2rem;
            }
            
          }
        }

        

        .content {
          flex: 1;
          width: 100%;
          position: relative;
          z-index: 2;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          @include media-breakpoint-down(xl) {
            flex-direction: column-reverse;
          }

          .layout {
            //padding-bottom: 3rem;

            @include media-breakpoint-down(xl) {
              padding-bottom: 0;
            }

            .ctas {
              display: flex;
              flex-direction: row;
              gap: 2rem;
              margin-bottom: 3.3rem;
              margin-top: 3rem;
    
              > div {
                max-height: 5.25rem;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .button {
                max-height: 5.25rem;
                @include public_sans_text(
                  clamp(1.2rem, 1vw, 1.4rem),
                  600,
                  normal,
                  1,
                  uppercase
                );
                padding: 1.4rem 1.8rem;
                border-radius: 1rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
    
                &.partner {
                  padding: 0.5rem 1.8rem;
                  img {
                    margin-right: 1.5rem;
                  }
                }
              }
            }
          }
          

          .variants {
            display: flex;
            //flex-direction: column;
            justify-content: flex-start;
            gap: 5rem;
            width: 100%;
            padding-bottom: 2rem;

            &.wrap {
              flex-direction: column;
            }

            &.sizes-variant-2 {
              flex-direction: column;
            }
            
            @include media-breakpoint-down(xl) {
              flex-direction: column;
              gap: 3rem;
            }

            .label {
              @include proxima_nova_text();
              font-weight: 800;
              font-size: 1.4rem;
              line-height: 1.8rem;
              color: $shadow_gray;
              //flex: 0 0 33%;
            }
            

            .sizes-wrapper {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              gap: 1.5rem;
              
              @include media-breakpoint-down(xl) {
                //flex-direction: row;
                //align-items: center;
                gap: 1.5rem;
              }
              
              .sizes-available {
                display: flex;

                .sizes-variant-1 {
                  display: flex;
                  flex-direction: row;
                  gap: 1.5rem;
                  //justify-content: space-between;
  
                  .size {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 0.5rem;
  
                    img {
                      width: 60px;
                      height: auto;
                      
  
                      @include media-breakpoint-down(xl) {
                        width: 50px;
                      }
        
                    } 
  
                    .name {
                      @include proxima_nova_text();
                      font-weight: 500;
                      font-size: 1.2rem;
                      line-height: 1.8rem;
                      color: $shadow_gray;
                      text-align: center;
                    }
                  }
                  
                }
  
                
  
                .sizes-variant-2 {
                  display: flex;
                  flex-direction: row;
                  // gap: 1.5rem;
                  //justify-content: space-between;
                  flex-wrap: wrap;
                  row-gap: 2rem;
  
                  .width, .length, .thickness {
                    display: flex;
                  }
  
                  div {
                    &:not(:first-child) {
                      &.width, &.length, &.thickness {
                        @include media-breakpoint-up(sm) {
                          border-left: 1px solid $medium_gray;
                          padding-left: 1.25rem;
                        }
                      }
                    }
                  }
                  
  
                  .size {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 1.5rem;
                    flex: 1;
                    //padding: 0 1.5rem;
                    padding-left: 1.5rem;
  
                    @include media-breakpoint-down(xl) {
                      gap: 0.5rem;
                    }
  
                    &:last-child {
                      padding-right: 1.5rem;
                    }
                    &:nth-child(1) {
                      padding-left: 0;
                    }
                    
  
  
                    &.last {
                      border-left: 1px solid $medium_gray;
  
                    }
  
                    .value {
                      @include praktika_text('Praktika ExtraBold');
                      font-size: 2rem;
                      line-height: 2rem;
                      color: $dark_blue;
  
                      @include media-breakpoint-down(xl) {
                        font-size: 1.5rem;
                        line-height: 1.5rem;
                      }
                    } 
  
                    .name {
                      @include proxima_nova_text();
                      font-weight: 500;
                      font-size: 1.2rem;
                      line-height: 1.8rem;
                      color: $shadow_gray;
                      width: 75%;
                      text-align: center;
  
                      @include media-breakpoint-down(xl) {
                        font-size: 1.1rem;
                        line-height: 1.5rem;
                      }
                    }
                  }
                }
              }

            }

            .colors-available {
              display: flex;
              flex-direction: column;
              gap: 1.25rem;

              .label {
                margin-bottom: 8px;
              }
              
              .colors-wrapper {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                
                @include media-breakpoint-down(xl) {
                  flex-direction: row;
                  align-items: center;
                  gap: 1.5rem;
                }
  
           
  
                .colors-variants {
                  display: flex;
                  flex-direction: row;
                  gap: 1.5rem;
                  // justify-content: space-between;
  
                  .color {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    // flex: 1;
                    
                    .fill {
                      width: 60px;
                      height: 60px;
                      border: 1px solid $medium_gray;
                      border-radius: 50%;
                      // background-color: #f00;
  
                      @include media-breakpoint-down(xl) {
                        width: 50px;
                        height: 50px;
                      }
        
                    }
  
                    .name {
                      @include proxima_nova_text();
                      font-weight: 500;
                      font-size: 1.2rem;
                      line-height: 1.8rem;
                      color: $shadow_gray;
                    }
                  }
                  
                }
              }
            }
          }
  
        }

        
        



        .splide.product_detail_hero {
          flex: 1;
          position: relative;

          @include media-breakpoint-down(xl) {
            margin-bottom: 6rem;
          }
    
          .splide__slide {
            > div {
              height: 100%;
              > article {
                height: 100%;
              }
            }
            .full_width_hero {
              height: 100%;
              .wrapper {
                height: 100%;
                video,
                .content-wrapper {
                  height: 100%;
                }
              }
            }
    
            .video-section-block {
              margin: unset;
              height: 100%;
              .video-section {
                height: 100%;
                .video-wrapper {
                  height: 100%;
                }
              }
            }
          }
    
          .featured_content_pagination {
            padding: 1rem;
            margin: 3rem auto 0;
            border-radius: 2.5rem;
            background-color: $medium_gray;
            width: fit-content;
            
            li {
              display: flex;
              place-content: center;
            }
          }
    
          .featured_content_page {
            border: none;
            background-color: $medium_gray;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
    
            &:before {
              content: "";
              display: block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: $white;
            }
            &.is-active {
              &:before {
                background-color: $medium_blue;
              }
            }
          }

          .media {
              padding: 1rem;
              width: 100%;
              position: relative;
              max-width: 600px;
              max-height: 500px;

              &.has_video {
                overflow: hidden;
                height: auto;
                border-radius: 20px;
                cursor: pointer;

                .play-button {
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    width: 100px;
                    height: 85px;

                    @include media-breakpoint-up(lg) {
                      width: auto;
                      height: auto;
                    }
                }

                video {
                  width: 100%;
                  height: auto;
                  object-fit: cover;
                  aspect-ratio: auto;
                  border-radius: 2rem;
                  pointer-events: none;

                    &.clicked {
                        pointer-events: auto;
                    }
                }

                iframe.video {
                  width: 100%;
                  height: auto;
                  object-fit: cover;
                  aspect-ratio: 16/9;
                  border-radius: 2rem;
                }
              }
    
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                aspect-ratio: auto;
              }
            }
        }
      }
    }
  }
// }