.company-highlights-block {
  // background-color: $medium_blue;
  background: linear-gradient(to top, $dark_blue, $medium_blue);
  color: $white;
  padding: 3rem 0;

  .heading-wrapper {
    padding: 5rem 0;

    @include media-breakpoint-down(xl) {
      padding: 2rem 0;
    }


    .heading {
      &, & p, * {
        @include proxima_nova_text();
        color: $light_aqua;
        font-size: 9rem;
        font-weight: 900;

        @include media-breakpoint-down(xxl) {
          font-size: 7rem;
        }
    
        @include media-breakpoint-down(xl) {
          font-size: 5.5rem;
        }
      }      
    }
  
    .subheading {
      &, & p, * {
        @include proxima_nova_text();
        color: $white;
        font-size: 9rem;
        font-weight: 900;
  
        @include media-breakpoint-down(xxl) {
          font-size: 7rem;
        }
    
        @include media-breakpoint-down(xl) {
          font-size: 5.5rem;
        }
      }
    }
  }
  
  

  .items-wrapper {
    padding: 2rem 0;

    > .row > div:not(:last-child) {
      & .item {
        border-bottom: 1px solid rgba($white, 0.2);
      }
    }

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 2rem 1rem;
      // border-bottom: 1px solid rgba($white, 0.2);
  
      @include media-breakpoint-down(xl) {
        padding: 2rem 0;
      }

      .item-image-wrapper {
        width: 42px;
        text-align: center;
      }
  
      .item-image {
        width: 42px;
        height: auto;
      }
  
      .item-text {
        &, & p, * {
          @include praktika_text('Praktika Bold');
          font-size: 2.4rem;
          font-weight: 500;
    
          @include media-breakpoint-down(xl) {
            font-size: 2rem;
          }
        }

        margin-left: 2rem;
      }
    }// item
  
  
    // henwr-1177 - overrides using the outer div nth-child
    > .row > div:nth-child(1) .item .item-image {
      width: 42px; // heart
    }

    > .row > div:nth-child(2) .item .item-image {
        width: 45px; // group
    }

    > .row > div:nth-child(3) .item .item-image {
        width: 44px; // trophy
    }

    > .row > div:nth-child(4) .item .item-image {
        width: 30px; // locations
    }

  } // items wrapper
}

  