.four-card-block-pdp {

  .grid_heading {
    display: flex;
    align-items: flex-end;
    margin-bottom: 4.5rem;
  

    .large_text {
      @include proxima_nova_text(3.5rem, 700, normal);
      display: inline-block;
      margin-right: 2.8rem;
      @include media-breakpoint-down(lg) {
        //margin-bottom: 4.5rem;
        font-size: 2.5rem;
      }
    }
      
    .spacer_wrap {
      @include media-breakpoint-down(xl) {
        width: calc(100% - 10rem);
        position: absolute;
        bottom: 0;
        left: 0;
      }

      @include media-breakpoint-up(xl) {
        flex: 1;
        position: relative;
        margin-right: 1.1rem;
      }

      .spacer {
        position: absolute;
        width: 100%;
        bottom: 0.9rem;
        border-bottom: 1px solid $medium_gray;
      }
    }

    .link {
      width: fit-content;
      @include public_sans_text(1.4rem, 500, normal, unset, uppercase);
      margin-bottom: 0.3rem;

      @include media-breakpoint-down(xl) {
        align-self: flex-end;
      }

      &:after {
        top: inherit;
        bottom: 1px;
      }
    }

  }

  .cards {
    //display: flex;


    .slide-wrapper {
      background: $white;
      .slide-image-wrapper {
        img {
          border-radius: 1.5rem;
        }
      }
    }

    .four-card-block_splide {
      visibility: visible;
    }
    .splide__slide {
      width: 100%;
    }

    .splide__track {
      width: 120%;
    }

    .splide__list {
      width: 80%;
    }
    
  
    .splide__slide {
      width: 300px;
    }
  }
  .splide__arrows {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;

    .splide__arrow {
      background: unset;
      border: none;

      &:before {
        content: "";
        display: block;
        width: 50px;
        height: 38px;
        opacity: 1;
      }

      &.splide__arrow--prev {
        &[disabled], &[disabled="true"] {
          opacity: 0.25;
        }

        &.disabled {
          opacity: 0.25;
        }
        &:before {
          background-image: url("/dist/images/previous-arrow.svg");
        }
      }

      &.splide__arrow--next {
        &[disabled], &[disabled="true"] {
          opacity: 0.25;
        }

        &:before {
          background-image: url("/dist/images/next-arrow.svg");
        }
      }
    }
  }
}