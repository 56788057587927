.product-page {
  .product-technical-installation, .accordion-type {
    position: relative;
    padding: 7rem 0;
    @include media-breakpoint-down(xl) {
      padding: 3rem 0;
    }

    display: flex;
    flex-direction: column;
    gap: 4rem;

    .section-header {
      display: flex;
      align-items: flex-end;

      .title {
        @include media-breakpoint-down(xl) {
          border-bottom: 1px solid $medium_gray;
          width: 100%;
          padding-bottom: 2rem;
        }
        

        h1 {
          @include proxima_nova_text(3.5rem, 700, normal, 1);
          display: inline-block;
          margin-right: 2.8rem;
          color: $medium_blue;

          @include media-breakpoint-down(xl) {
            margin: 0;
            font-size: 2.5rem;
          }
        }
      }

      .spacer_wrap {
        @include media-breakpoint-down(xl) {
          display: none;
        }


        flex: 1;
        position: relative;
        .spacer {
          position: absolute;
          width: 100%;
          bottom: 0.6rem;
          border-bottom: 1px solid $medium_gray;
        }
        margin-right: 1.1rem;
      }
    }

    .accordion {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @include media-breakpoint-up(xl) {
        padding: 0 5rem;
      }


      // accordion item
      .accordion-section {
        border-radius: 0;
        border: none;
        border: 1px solid $medium_gray;
        padding: 0 2.4rem;


        p {
          margin: 0;
          padding: 0;
        }

        .accordion-header {

          &, & h2 {
            @include public_sans_text();
            font-size: 2.2rem;
            line-height: 3.3rem;
            font-weight: 500;
            color: $medium_blue;
            padding: 0;
            margin: 0;

            @include media-breakpoint-down(xl) {
              font-size: 1.4rem;
              line-height: 2.1rem;
            }
          }

          .accordion-button {
            border: none;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            box-shadow: none;
            background: none;
            padding: 2rem 0;

            &:hover,
            &:focus,
            &:active {
              border: none;
              border-top-right-radius: 0;
              border-top-left-radius: 0;
              box-shadow: none;
              background: none;
            }

            .accordion-content {
              margin-right: 2rem;
            }
            
            &:after {
              background-image: url("/dist/images/accordion-plus.svg");
              width: 32px;
              height: 32px;
              background-size: 100%;
              position: absolute;
              // right: 0;
              right: -9px;

              @include media-breakpoint-down(xl) {
                right: -9px;
              }
            }

            &:not(.collapsed) {
              background: none;
              //margin-bottom: 2rem;
              border-bottom: 1px solid $medium_gray;
              
              @include media-breakpoint-down(xl) {
                //margin-bottom: 2rem;
                border-bottom: 1px solid $medium_gray;
              }

              &:after {
                background-image: url("/dist/images/accordion-minus.svg");
                width: 18px;
                height: 18px;
                background-size: 100%;
                position: absolute;
                // right: 9px;
                // top: 9px;
                right: 0;
                top: 15px;
                
                @include media-breakpoint-down(xl) {
                  right: 0;
                  top: 9px;
                }
                
              }
            }
          }
        } // . accordion-header


        .accordion-collapse {
          .accordion-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 2rem;
            padding: 0;

            * { 
              // max-width: inherit;
              // width: fill-available;
            }


            video, iframe.video {
              width: 100%;
              margin: 2.1rem 0;

              @include media-breakpoint-up(xl) {
                max-width: 70rem;
              }
            }

            iframe.video {
                aspect-ratio: 16/9;
            }

            .description {
              @include public_sans_text();
              font-size: 1.8rem;
              line-height: 2.7rem;
              font-weight: 500;
              color: $black;

              text-align: center;
              max-width: 70rem;
              margin: 1.5rem auto;
            }

            ul {
              list-style: none;
              margin: 0;
              padding: 0;
              outline: none;
              width: 100%;

              &.has-copy-description {
                border-top: 1px solid $medium_gray;
              }

              li {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 1.5rem;

                padding: 1.5rem 0;

                &:not(:last-child) {
                  border-bottom: 1px solid $medium_gray;
                }
                
                @include media-breakpoint-down(xl) {
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 0.5rem;
                  width: 100%;
                }

                p {
                  @include public_sans_text();
                  font-size: 1.6rem;
                  line-height: 2.4rem;
                  font-weight: 500;
                  color: $dark_blue;
                }

                a.download-wrapper {
                  text-decoration: none;

                  display: flex;
                  flex-direction: row;
                  gap: 1.5rem;

                  .download {
                    @include public_sans_text();
                    font-size: 1.3rem;
                    line-height: 2.8rem;
                    font-weight: 700;
                    color: $orange;
                  }

                  .icon-wrapper {
                    height: 28px;
                    width: 24px;
                    display: flex;
                    img {
                      height: 100%;
                      width: auto;
                    }
                  }
                }
                

              }
            }

            .copy {
              margin: 1.5rem 0;
              align-self: flex-start;

              p {
                &:not(:last-child) {
                  padding-bottom: 3rem;
                }
              }

              ul {
                list-style: disc;
                margin: 0;
                padding: 0;
                margin-bottom: 1rem;
                padding-left: 2rem;
                outline: none;
                width: 100%;

                li {
                  display: list-item;
            
                  padding: 0;

                  &:not(:last-child) {
                    border-bottom: none;
                  }
                }
              }

             /*  &, & * {
                @include public_sans_text();
                font-size: 1.6rem;
                line-height: 2.4rem;
                font-weight: 500;
                color: $dark_blue;
                text-align: left;
              } */
            }
          }
        } // .collapse
      } // .accordion-section
    }// .accordion

  }




  .product-faq {}
}