.card.card-split {
  border: none;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 2rem;

  @include media-breakpoint-up(xxl) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > * {
      flex: 1;
    }

    column-gap: 2rem;
  }
  

  // image
  .card-image {
    position: relative;

    img {
      width: 100%;
      height: auto;
      //aspect-ratio: 1;
      -o-object-fit: cover;
      object-fit: cover;
      object-position: top;
      border-radius: 10px;
    }

    .play-wrapper {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        width: 100%;
        height: 100%;

        .play-button {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            width: 112px;
            height: 89px;
        }
    }
  }


  // content

  //eyebrows
  .eyebrows {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 2.5rem;

    .category-tag:first-of-type {
        @extend .font_dark_blue;
        @extend .border_yellow;
    }

    .category-tag:nth-of-type(2) {
        @extend .font_shadow_gray;
        @extend .border_medium_gray;
    }
  }

  // title
  .card-title {
    @include public_sans_text(1.8rem, 500, normal, 2.4rem);
    color: $medium_blue;
  }


  // description
  .card-text {
    @include public_sans_text(1.4rem, 500, normal, 2.0rem);
    color: $shadow_gray;
  }


  // link
  .card-link {
    @include public_sans_text(1.4rem, 700, normal, 1);
    color: $light_blue;
    text-transform: uppercase;
    text-decoration: none;
  }


}