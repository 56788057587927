.tabbed-content-block {  
  // padding: 7rem 0;

  // @include media-breakpoint-down(xl) {
  //   padding: 3rem 0;
  // }
  

  ul.nav {
    background-color: $dark_gray;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 12rem;
    padding-right: 12rem;
    
    @include media-breakpoint-down(xl) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: flex-start;
    justify-content: space-between;
    // gap: 6rem;
    overflow-x: auto;
    flex-wrap: nowrap;
    // hide scrollbars in overflow auto
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-down(xl) {
      justify-content: flex-start;
      gap: 6rem;
    }


    &.sticky {
      position: fixed;
      top: 0;
      max-width: $max_content_width;
      z-index: 9;
    }

    li.nav-item {
      padding: 0;
      margin: 0;
      height: max-content;

      a {
        background-color: transparent;
        border: none;
        padding: 0;
        @include praktika_text();
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2rem;
        color: $white;
        letter-spacing: 0.25px;

        &:hover {
          color: $light_aqua;
        }

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 4px;
          background-color: transparent;
          border-radius: 5px;
          margin: 0.35rem auto 0;
        }

        &.active {
          color: $light_aqua;
          background-color: transparent;
          border: none;
          // border-bottom: 4px solid $light_aqua;
          // padding-bottom: 0.35rem;

          &:after {
            background-color: $light_aqua;
          }
        }
      }
    }
  }


  .tab-content {
    // override defaults
    padding: 0;
  }



  // section layout
  .tabbed-content-section-block {
    // override defaults
    .tab-content {
      margin: 0;
    }
  }
}
