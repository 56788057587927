.three-card-block {
  padding-top: 7rem;
  padding-bottom: 7rem;

  @include media-breakpoint-down(lg) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .grid_heading {
    display: flex;
    align-items: flex-end;
    margin-bottom: 4.5rem;

    @include media-breakpoint-down(xl) {
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      //padding-right: 2rem; // last-card-fix
    }

    .large_text {
      @include proxima_nova_text(3.5rem, 700, normal, 1);
      display: inline-block;
      margin-right: 2.8rem;

      @include media-breakpoint-down(xl) {
        font-size: 2.5rem;
        margin-bottom: 2rem;
      }
    }


    .spacer_wrap {
      flex: 1;
      position: relative;


      @include media-breakpoint-down(xl) {
        width: calc(100% - 10rem);
        position: absolute;
        bottom: 0;
        left: 0;
      }


      .spacer {
        position: absolute;
        width: 100%;
        bottom: 0.6rem;
        border-bottom: 0.1rem solid $medium_gray;
      }
      margin-right: 1.1rem;
    }
    .link {
      width: fit-content;
      @include public_sans_text(1.4rem, 500, normal, unset, uppercase);
      margin-bottom: 0.3rem;



      @include media-breakpoint-down(xl) {
        align-self: flex-end;
      }
    }
  }
  .grid_wrapper {
    padding: 0;
  }
  .splide__slide {
    width: 30rem;
  }

  @include media-breakpoint-down(lg) {
      .splide__track {
        width: 120%; // last-card-fix

        .splide__list {
            width: 80%;
        }
      }
  }

  .three-card-block_splide {
    display: flex;
    flex-direction: column;
  }

  @include media-breakpoint-up(lg) {
    .grid_wrapper {
      display: grid;
      gap: 2.4rem;
      grid-template-columns: repeat(3, 1fr);
    }
    .three-card-block_splide {
      visibility: visible;
    }
    .splide__slide {
      width: 100%;
    }

    .splide__track {
      width: 100%;
    }
  }
  @include media-breakpoint-down(lg) {
    .splide__track {
      order: 0;
    }
    .no__splide__track {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .splide__slide {
        width: 100%;
        max-width: 33.8rem;
        margin-bottom: 3rem;
      }
    }
  }

  .splide__arrows {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    order: 1;

    @include media-breakpoint-down(xl) {
      padding-right: 2rem; // last-card-fix
    }

    .splide__arrow {
      background: unset;
      border: none;
      svg {
        display: none;
      }

      &:before {
        content: "";
        display: block;
        width: 5rem;
        height: 3.8rem;
        opacity: 1;
      }

      &.splide__arrow--prev {
        &[disabled], &[disabled="true"] {
          opacity: 0.25;
        }

        &.disabled {
          opacity: 0.25;
        }
        &:before {
          background-image: url("/dist/images/previous-arrow.svg");
        }
      }

      &.splide__arrow--next {
        &[disabled], &[disabled="true"] {
          opacity: 0.25;
        }
        
        &:before {
          background-image: url("/dist/images/next-arrow.svg");
        }
      }
    }
  }

  &.gray,
  &.white {
    .grid_heading {
      .large_text {
        color: $medium_blue;
      }
    }
    .card-body {
      .eyebrows {
        .button {
          color: $shadow_gray;
        }
      }
      .card-title {
        color: $medium_blue;
      }

      .card-text {
        color: $shadow_gray;
      }
    }
  }

  &.gray {
    background-color: $light_gray;
  }

  &.white {
    background-color: $white;
  }

  &.blue, &.dark {
    background-color: $dark_blue;

    .grid_heading {

      .spacer_wrap {
        .spacer {
          border-bottom: 0.1rem solid rgba($medium_gray, 0.2);
        }
      }
    }

    .card .eyebrows .category-tag {
        color: $white;
    }

    .card {
      .date {
        color: $white;
      }
    }
  }

  .load-more {
    @include public_sans_text();
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 700;
    border-radius: 1rem;
    height: 4rem;
    text-transform: uppercase;
    background-color: $light_blue;
    color: $white;
    border: none;
    padding: 0 7rem;
    margin: 7rem auto;
    cursor: pointer;

    @include media-breakpoint-down(xl) {
      padding: 0;
      width: 100%;
    }

    &:hover {
      background-color: $white;
      color: $light_blue;
      border: 0.2rem solid $light_blue;
    }

    &[disabled] {
      background-color: $medium_gray;
      color: $dark_gray;
      cursor: not-allowed;

      &:hover {
        background-color: $medium_gray;
        color: $dark_gray;
        border: none;
      }
    }
  }
}
