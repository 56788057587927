/**
    * Fonts
    * -----------------------------------------------------------------------------
    * Define the font families and weights used in your theme.
    *
    * Supports italics and regular font styles.
    * Weight: 400, 500, 700, 800, 900
    *
    * @package Henry
*/
html {
  font-size: 62.5%;
  body {
    font-size: 1.6rem;
  }

  p {
    margin: 0;
  }
}

// fonts

/**
    * Proxima Nova
    *
    * @package Henry
*/
@import url("https://use.typekit.net/iku6row.css");

/**
    * Public Sans
    *
    * @package Henry
*/
// public sans
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");

/**
    * Praktika
    *
    * @package Henry
*/
// todo: add Praktika font

/**
    * Typography
    * -----------------------------------------------------------------------------
    * Mixins and variables for typography.
    *
    * @package Henry
*/

/**
    * Text
    *
    * @package Henry
*/
@mixin text(
  $font-size: 4rem,
  $font-weight: 500,
  $font-style: normal,
  $line-height: 1,
  $text-transform: none
) {
  font-size: $font-size;
  font-weight: $font-weight;
  font-style: $font-style;
  line-height: $line-height;
  text-transform: $text-transform;
}

/**
    * Proxima Nova Text
    *
    * @package Henry
*/
@mixin proxima_nova_text(
  $font-size: 4rem,
  $font-weight: 500,
  $font-style: normal,
  $line-height: 1,
  $text-transform: none
) {
  font-family: proxima-nova, serif;
  @include text(
    $font-size,
    $font-weight,
    $font-style,
    $line-height,
    $text-transform
  );
}

/**
    * Public Sans Text
    *
    * @package Henry
*/
@mixin public_sans_text(
  $font-size: 4rem,
  $font-weight: 500,
  $font-style: normal,
  $line-height: 1,
  $text-transform: none
) {
  font-family: "Public Sans", sans-serif;
  @include text(
    $font-size,
    $font-weight,
    $font-style,
    $line-height,
    $text-transform
  );
}

/**
  * Praktika Text - add when available
  *
  * @package Henry
*/

// @import 'fonts/praktika.css';

@font-face {
  font-family: "Praktika Black";
  src: local("Praktika Black"),
    url("../../../fonts/Praktika-Black.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Praktika BlackExtended";
  src: url("../../../fonts/Praktika-BlackExtended.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Praktika BlackExtItalic";
  src: url("../../../fonts/Praktika-BlackExtItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Praktika BlackItalic";
  src: url("../../../fonts/Praktika-BlackItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Praktika Bold";
  src: url("../../../fonts/Praktika-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Praktika BoldCondensed";
  src: url("../../../fonts/Praktika-BoldCondensed.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika BoldCondItalic";
  src: url("../../../fonts/Praktika-BoldCondItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika BoldExtended";
  src: url("../../../fonts/Praktika-BoldExtended.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika BoldExtItalic";
  src: url("../../../fonts/Praktika-BoldExtItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika BoldItalic";
  src: url("../../../fonts/Praktika-BoldItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Praktika Condensed";
  src: url("../../../fonts/Praktika-Condensed.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Praktika CondItalic";
  src: url("../../../fonts/Praktika-CondItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Praktika Extended";
  src: url("../../../fonts/Praktika-Extended.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Praktika ExtItalic";
  src: url("../../../fonts/Praktika-ExtItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Praktika ExtraBold";
  src: url("../../../fonts/Praktika-ExtraBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika ExtraBoldCondensed";
  src: url("../../../fonts/Praktika-ExtraBoldCondensed.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika ExtraBoldCondItalic";
  src: url("../../../fonts/Praktika-ExtraBoldCondItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika ExtraBoldExtended";
  src: url("../../../fonts/Praktika-ExtraBoldExtended.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika ExtraBoldExtItalic";
  src: url("../../../fonts/Praktika-ExtraBoldExtItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika ExtraBoldItalic";
  src: url("../../../fonts/Praktika-ExtraBoldItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Praktika ExtraLight";
  src: url("../../../fonts/Praktika-ExtraLight.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika ExtraLightCondensed";
  src: url("../../../fonts/Praktika-ExtraLightCondensed.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika ExtraLightCondItalic";
  src: url("../../../fonts/Praktika-ExtraLightCondItalic.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika ExtraLightExtended";
  src: url("../../../fonts/Praktika-ExtraLightExtended.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika ExtraLightExtItalic";
  src: url("../../../fonts/Praktika-ExtraLightExtItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika ExtraLightItalic";
  src: url("../../../fonts/Praktika-ExtraLightItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Praktika Light";
  src: url("../../../fonts/Praktika-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika LightCondensed";
  src: url("../../../fonts/Praktika-LightCondensed.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika LightCondItalic";
  src: url("../../../fonts/Praktika-LightCondItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika LightExtended";
  src: url("../../../fonts/Praktika-LightExtended.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika LightExtItalic";
  src: url("../../../fonts/Praktika-LightExtItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika LightItalic";
  src: url("../../../fonts/Praktika-LightItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Praktika Medium";
  src: url("../../../fonts/Praktika-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika MediumCondensed";
  src: url("../../../fonts/Praktika-MediumCondensed.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika MediumCondItalic";
  src: url("../../../fonts/Praktika-MediumCondItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika MediumExtended";
  src: url("../../../fonts/Praktika-MediumExtended.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika MediumExtItalic";
  src: url("../../../fonts/Praktika-MediumExtItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika MediumItalic";
  src: url("../../../fonts/Praktika-MediumItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Praktika Regular";
  src: url("../../../fonts/Praktika-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Praktika RegularItalic";
  src: url("../../../fonts/Praktika-RegularItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@mixin praktika_text(
  $font-family: "Praktika Regular",
  $font-size: 4rem,
  $font-weight: 500,
  $font-style: normal,
  $line-height: 1,
  $text-transform: none
) {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  font-style: $font-style;
  line-height: $line-height;
  text-transform: $text-transform;
}

// globals

div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p {
  @include public_sans_text();
}

strong {
  @include proxima_nova_text();
  font-weight: 700;
}

.underline {
    text-decoration: underline;
}
