.product-card-list {
  .splide__track {
    .splide__list {
      .splide__slide {
        height: unset!important;
      }
    }
  }
}

.product-card {
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  .slide-image-wrapper {
    img {
      width: 100%;
      border-top-left-radius: 1.5rem;
      border-top-right-radius: 1.5rem;
      max-height: 30rem;
      height: 100%;
      object-fit: cover;
      height: 320px;

      @include media-breakpoint-down(xl) {
        height: 250px;
      }
    }
  }

  .slide-text-wrapper {
    background-color: $light_gray;
  }

  .slide-name {
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: $medium_blue;
    padding: 1.5rem 0;
  }

  .slide-link {
    display: block;
    padding-bottom: 2rem;

    a {
      font-size: 1.4rem;
      line-height: 2.8rem;
      color: $light_blue;
      text-decoration: none;
      display: block;
      text-transform: uppercase;

      &:after {
        right: inherit;
      }
    }
  }
}
